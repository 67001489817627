import {
  Box,
  BoxProps,
  Button,
  Center,
  chakra,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack,
  Text,
} from "@chakra-ui/react";
import {
  EmrBed,
  EmrCheckmarkShield,
  EmrDischarged,
  EmrTransportation,
  EmrKey,
  EmrLabs,
  EmrPlus,
  EmrProfileCircle,
  EmrRadiology,
  EmrVitals,
  SiChevronLeft,
  EmrCheck,
} from "@medstonetech/slate-icons";
import { useYupValidationResolver } from "hooks";
import {
  DocumentOutline,
  EmrLogoGray,
  Hand,
  NurseHat,
  PillsBottle,
  PrintOutline,
} from "icons";
import {
  useSignedSectionsFiles,
  useSummaryMiscellaneous,
  useGenerateSummaryDocument,
  UseGenerateSummaryDocumentPayload,
  ChartSummaryResponse,
  useChartSummary,
} from "modules/charts-shared/api";
import * as React from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import {
  Card,
  CheckboxListGroup,
  CheckboxListItem,
  Loading,
  PdfViewer,
  PdfViewerActivePage,
  PdfViewerContainer,
  PdfViewerHeader,
  PdfViewerPageController,
  PdfViewerPreview,
  Textarea,
  ToolbarHeader,
  WarningDialog,
} from "shared";
import {
  dateWithoutTimezone,
  enumMapper,
  extractApiErrorMessage,
  isLastIndex,
} from "utils";
import * as yup from "yup";
import { SignedSection } from "modules/charts-shared/api/mutations";
import { AdditionalDocumentModal } from "./AdditionalDocumentModal";
import {
  DocumentItem,
  SectionItem,
  SignedItem,
  SectionItemRemove,
} from "./SectionListItem";
import { ProviderChartCode, SummaryReasons, TriageChartCode } from "enums";
import {
  ChartRouteBaseParams,
  NursingCharts,
  ProviderCharts,
  ProviderChartSection,
  sortProviderSections,
  TriageChartSection,
} from "modules";
import { PROVIDER_CHARTS } from "modules/provider/constants";
import { useIsFetching } from "react-query";
import { EncounterInfoResponse, useEncounterInfo } from "modules/reception/api";
import { useNavigate, useParams } from "react-router-dom";
import { differenceInYears, format } from "date-fns";
import { FACILITY_ADDRESS } from "system-constants/constants";
import { NURSE_CHARTS } from "modules/triage/constants";
import { useState } from "react";
import { ChartTrackingContextProvider } from "contexts";
import { PDFDocument } from "pdf-lib";
import toPdf, { Resolution } from "react-to-pdf";

type SummaryForm = {
  selectedSections: string[];
  miscFilesToAdd: string[];
};

type SectionItemOption = {
  key: string;
  value: string;
  description: string;
  icon: JSX.Element;
  uploadedFile?: boolean;
};

type SignedSectionItemOption = {
  type: SignedSection;
} & SectionItemOption;

type AdditionalDocumentItemOption = {
  value: string;
  description: string;
};

type SummaryOptions = {
  sectionUploadItems: SignedSectionItemOption[];
  miscFilesToAdd: AdditionalDocumentItemOption[];
};

const SUMMARY_SCHEMA = yup
  .object({
    selectedSections: yup.array(),
    miscFilesToAdd: yup.array(),
  })
  .test(
    "hasSections",
    "Please select at least one element",
    (value) =>
      (value.selectedSections || []).length > 0 ||
      (value.miscFilesToAdd || []).length > 0
  );

const SUMMARY_SECTION_ITEMS: SectionItemOption[] = [
  {
    key: "addPatientDemographics",
    value: "addPatientDemographics",
    description: "Patient Demographics",
    icon: <Icon h="18px" w="18px" color="gray.700" as={EmrProfileCircle} />,
  },
  {
    key: "addRegistrationDocuments",
    value: "addRegistrationDocuments",
    description: "Registration Documents",
    icon: <Icon h="18px" w="18px" color="gray.700" as={EmrKey} />,
  },
  {
    key: "addInsuranceInformation",
    value: "addInsuranceInformation",
    description: "Insurance Information",
    icon: <Icon h="18px" w="18px" color="gray.700" as={EmrCheckmarkShield} />,
  },
  {
    key: "addNursingChartSnapshots",
    value: "addNursingChartSnapshots",
    description: "Nursing Chart Snapshots",
    icon: <Icon h="18px" w="18px" color="gray.700" as={NurseHat} />,
  },
  {
    key: "addNursingChartSummary",
    value: "addNursingChartSummary",
    description: "Nursing Chart Summary",
    icon: <Icon h="18px" w="18px" color="gray.700" as={NurseHat} />,
  },
  {
    key: "addProviderChartSnapshots",
    value: "addProviderChartSnapshots",
    description: "Provider Chart Snapshots",
    icon: <Icon h="18px" w="18px" color="gray.700" as={EmrVitals} />,
  },
  {
    key: "addProviderChartSummary",
    value: "addProviderChartSummary",
    description: "Provider Chart Summary",
    icon: <Icon h="18px" w="18px" color="gray.700" as={EmrVitals} />,
  },
  {
    key: "addLabReports",
    value: "addLabReports",
    description: "Lab Reports",
    icon: <Icon h="18px" w="18px" color="gray.700" as={EmrLabs} />,
  },
  {
    key: "addRadiologyReports",
    value: "addRadiologyReports",
    description: "Radiology Reports",
    icon: <Icon h="18px" w="18px" color="gray.700" as={EmrRadiology} />,
  },
  {
    key: "addMedicationsReport",
    value: "addMedicationsReport",
    description: "Meds & IV",
    icon: <Icon h="18px" w="18px" color="blue" as={PillsBottle} />,
  },
];

const SUMMARY_SECTION_UPLOAD_ITEMS: SignedSectionItemOption[] = [
  {
    key: "addDischargeFile",
    value: "addDischargeFile",
    description: "Discharge Forms - Signed",
    icon: <Icon h="18px" w="18px" color="gray.700" as={EmrDischarged} />,
    uploadedFile: false,
    type: "Discharge",
  },
  {
    key: "addAmaFile",
    value: "addAmaFile",
    description: "AMA Forms - Signed",
    icon: <Icon h="18px" w="18px" color="gray.700" as={Hand} />,
    uploadedFile: false,
    type: "Ama",
  },
  {
    key: "addTransferFile",
    value: "addTransferFile",
    description: "Transfer Documents - Signed",
    icon: <Icon h="18px" w="18px" color="gray.700" as={EmrTransportation} />,
    uploadedFile: false,
    type: "Transfer",
  },
  {
    key: "addObservationFile",
    value: "addObservationFile",
    description: "Observation Consent - Signed",
    icon: <Icon h="18px" w="18px" color="gray.700" as={EmrBed} />,
    uploadedFile: false,
    type: "Observation",
  },
];

function transformProviderCharts(
  input: ChartSummaryResponse | undefined
): Partial<Record<ProviderChartCode, React.ReactElement[]>> {
  if (!input) return {};

  const transformedCharts: Partial<
    Record<ProviderChartCode, React.ReactElement[]>
  > = {};

  Object.entries(input.charts).forEach(([chartCode, sections]) => {
    sortProviderSections(sections).forEach((sectionCode) => {
      const Chart =
        ProviderCharts[chartCode as ProviderChartCode]?.[
          sectionCode as ProviderChartSection
        ];
      if (Chart) {
        transformedCharts[chartCode as ProviderChartCode] = [
          ...(transformedCharts[chartCode as ProviderChartCode] || []),
          <Chart />,
        ];
      }
    });
  });
  return transformedCharts;
}

function transformNursingCharts(
  input: ChartSummaryResponse | undefined
): Partial<Record<TriageChartCode, React.ReactElement[]>> {
  if (!input) return {};

  const transformedCharts: Partial<
    Record<TriageChartCode, React.ReactElement[]>
  > = {};

  Object.entries(input.charts).forEach(([chartCode, sections]) => {
    sections.forEach((sectionCode) => {
      const Chart =
        NursingCharts[chartCode as TriageChartCode]?.[
          sectionCode as TriageChartSection
        ];
      if (Chart) {
        transformedCharts[chartCode as TriageChartCode] = [
          ...(transformedCharts[chartCode as TriageChartCode] || []),
          <Chart />,
        ];
      }
    });
  });

  return transformedCharts;
}

function ChartSummaryHeader(props: {
  encounterInfo?: EncounterInfoResponse;
  chartName: string;
}) {
  const { encounterInfo, chartName } = props;

  const hostname = window.location.hostname;

  const identifier = hostname.split(".")[0];

  const address = FACILITY_ADDRESS?.[identifier] || "N/A";

  return (
    <Box p={4} w="full" borderBottom="1px" borderColor="gray.200">
      <HStack spacing={2} align="start" w="full" justify="space-between">
        <VStack justifyContent="flex-start" alignItems="flex-start">
          <Text color="gray.500" fontSize="xl">
            Hospitality Health ER{" "}
            {hostname.split(".")[0].charAt(0).toUpperCase() +
              hostname.split(".")[0].slice(1)}
            • {address}
          </Text>

          <Text fontSize="2xl" fontWeight="bold">
            {`${encounterInfo?.lastName}, ${encounterInfo?.firstName}`}
          </Text>

          <HStack spacing={2} fontSize="sm">
            <Text>MRN #: {encounterInfo?.mrn}</Text>
            <Text>•</Text>
            <Text>
              DOB:{" "}
              {encounterInfo?.dob &&
                format(
                  dateWithoutTimezone(encounterInfo?.dob || ""),
                  "MM/dd/yyyy"
                )}
            </Text>
            <Text>•</Text>
            <Text>
              AGE:{" "}
              {encounterInfo?.dob &&
                differenceInYears(new Date(), new Date(encounterInfo?.dob))}
            </Text>
            <Text>•</Text>
            <Text>SEX: {encounterInfo?.gender === "Female" ? "F" : "M"}</Text>
            <Text>•</Text>
            <Text>
              DOS{" "}
              {encounterInfo?.createdAt &&
                format(
                  dateWithoutTimezone(encounterInfo?.createdAt || ""),
                  "MM/dd/yyyy"
                )}
            </Text>
          </HStack>
          <chakra.span fontWeight="500" color="blue" fontSize="xl">
            {chartName}
          </chakra.span>
        </VStack>

        <Icon as={EmrLogoGray} fontSize="8rem" />
      </HStack>
    </Box>
  );
}

function Summary() {
  const { encounterId = "" } = useParams<{ encounterId: string }>();

  const [reason, setReason] = React.useState<SummaryReasons | undefined>(
    undefined
  );
  const [otherReason, setOtherReason] = React.useState<string | undefined>(
    undefined
  );

  const toast = useToast();

  const navigate = useNavigate();

  const [sections, setSections] = React.useState<SummaryOptions>({
    sectionUploadItems: [...SUMMARY_SECTION_UPLOAD_ITEMS],
    miscFilesToAdd: [],
  });

  const [mode, setMode] = React.useState<
    "reason" | "selector" | "editor" | "file"
  >("reason");
  const [file, setFile] = React.useState<Nullable<File | string | Blob>>(null);

  const { data: providerSummaryCharts } = useChartSummary({
    encounterId,
    chartType: "Chart",
  });

  const { data: nursingSummaryCharts } = useChartSummary({
    encounterId,
    chartType: "Triage",
  });

  const { data: additionalDocuments } = useSummaryMiscellaneous(
    encounterId || ""
  );

  const { data: signedDocuments } = useSignedSectionsFiles(encounterId || "");

  const yupResolver = useYupValidationResolver(SUMMARY_SCHEMA);

  const form = useForm<SummaryForm>({
    mode: "onSubmit",
    defaultValues: { selectedSections: [], miscFilesToAdd: [] },
    resolver: yupResolver,
  });

  const {
    handleSubmit,
    formState: { isValid },
    watch,
  } = form;

  const selectedValues = watch("selectedSections");

  const removeUploadFile = (value: string) => {
    setSections((currentSections) => {
      const changeSection = currentSections.sectionUploadItems.find(
        (sectionfFile) => sectionfFile.value === value
      );
      if (changeSection) changeSection.uploadedFile = false;
      return { ...currentSections };
    });
    const currentValues = form
      .getValues()
      .selectedSections.filter((section) => section !== value);
    form.setValue("selectedSections", currentValues, { shouldValidate: true });
  };

  const removeAdditionalDocument = (value: string) => {
    setSections({
      ...sections,
      miscFilesToAdd: sections.miscFilesToAdd.filter(
        (addDoc) => addDoc.value !== value
      ),
    });
    const currentValues = form
      .getValues()
      .miscFilesToAdd.filter((section) => section !== value);
    form.setValue("miscFilesToAdd", currentValues, {
      shouldValidate: true,
    });
  };

  const { mutateAsync: generateSummaryDocument, isLoading: isLoadingDocument } =
    useGenerateSummaryDocument(encounterId || "", reason, otherReason);

  const { nursing, provider } = React.useMemo(() => {
    const providerChartsData = providerSummaryCharts?.data.charts;

    const triageSharedSections: string[] = [
      "ORHI",
      "SAMRX",
      "SARI",
      "SDIS",
      "SEKG",
      "SIVM",
      "SMHX",
      "SPGR",
      "SSUP",
      "STRE",
      "SVIT",
    ];

    const providerChartsDataWithoutDuplicates = providerChartsData
      ? Object.fromEntries(
          Object.entries(providerChartsData).map(([key, chartSections]) => [
            key,
            chartSections.filter(
              (section) => !triageSharedSections.includes(section)
            ),
          ])
        )
      : {};

    return {
      nursing: transformNursingCharts(nursingSummaryCharts?.data),
      provider: selectedValues.includes("addNursingChartSnapshots")
        ? transformProviderCharts({
            charts: providerChartsDataWithoutDuplicates,
          })
        : transformProviderCharts(providerSummaryCharts?.data),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerSummaryCharts?.data, nursingSummaryCharts?.data]);

  const onSubmit: SubmitHandler<SummaryForm> = async (data: SummaryForm) => {
    try {
      const payload: UseGenerateSummaryDocumentPayload = {
        addPatientDemographics: false,
        addRegistrationDocuments: false,
        addInsuranceInformation: false,
        addNursingChartSummary: false,
        addProviderChartSummary: false,
        addLabReports: false,
        addRadiologyReports: false,
        addDischargeFile: false,
        addObservationFile: false,
        addMedicationsReport: false,
        miscFilesToAdd: data.miscFilesToAdd,
      };
      (
        data.selectedSections as (keyof Omit<
          UseGenerateSummaryDocumentPayload,
          "miscFilesToAdd"
        >)[]
      ).forEach((key) => {
        payload[key] = true;
      });
      const { data: newFile } = await generateSummaryDocument(payload);
      setFile(newFile);
      setMode("file");
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const onChangeReason = (
    value: SummaryReasons,
    description: string | undefined
  ) => {
    setReason(value);
    setOtherReason(description);
    setMode("selector");
  };

  React.useEffect(() => {
    if (signedDocuments?.data) {
      setSections((currentSections) => {
        return {
          ...currentSections,
          sectionUploadItems: [
            {
              ...currentSections.sectionUploadItems[0],
              uploadedFile: !!signedDocuments.data.hasDischargeFormSigned,
            },
            {
              ...currentSections.sectionUploadItems[1],
              uploadedFile: !!signedDocuments.data.hasAmaFormSigned,
            },
            {
              ...currentSections.sectionUploadItems[2],
              uploadedFile: !!signedDocuments.data.hasTransferDocumentsSigned,
            },
            {
              ...currentSections.sectionUploadItems[3],
              uploadedFile: !!signedDocuments.data.hasObservationConsentSigned,
            },
          ],
        };
      });
    }
  }, [signedDocuments]);

  if (mode === "reason")
    return <SummaryReasonModal isOpen onChangeReason={onChangeReason} />;

  if (mode === "selector" || mode === "editor")
    return (
      <Modal isOpen onClose={() => navigate("../")}>
        <ModalOverlay />
        <ModalContent
          zIndex={1000}
          borderRadius="md"
          bgColor="gray.50"
          minHeight="462px"
          width="510px"
          maxWidth="510px"
        >
          <ModalHeader borderTopRadius="md">
            <Flex justifyContent="space-between" alignItems="center">
              <Button
                variant="label"
                color="blue"
                onClick={() => setMode("reason")}
              >
                Cancel
              </Button>
              <Box>Chart Summary Sections</Box>
              <Button
                variant="label"
                color="blue"
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid || isLoadingDocument}
                isLoading={isLoadingDocument}
              >
                Generate
              </Button>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <FormProvider {...form}>
              <Box height="100%" bgColor="gray.50">
                <Box height="calc(100% - 80px)">
                  <HStack height="100%" alignItems="start" spacing="0px">
                    <Box overflow="auto" height="100%" width="510px">
                      {mode === "selector" ? (
                        <>
                          <SectionsSelector
                            form={form}
                            encounterId={encounterId || ""}
                            sectionItems={SUMMARY_SECTION_ITEMS}
                            sectionUploadItems={sections.sectionUploadItems}
                            onEditClick={() => setMode("editor")}
                          />
                          <AdditionalDocumentsSelector
                            additionalDocuments={
                              additionalDocuments?.data || []
                            }
                            encounterId={encounterId || ""}
                            form={form}
                          />
                        </>
                      ) : (
                        <>
                          <SectionsEditor
                            width="510px"
                            mb="15px"
                            encounterId={encounterId || ""}
                            sectionItems={SUMMARY_SECTION_ITEMS}
                            sectionUploadItems={sections.sectionUploadItems}
                            onFinish={() => setMode("selector")}
                            onDelete={(idDoc) => removeUploadFile(idDoc)}
                          />
                          <AdditionalDocumentsEditor
                            additionalDocuments={
                              additionalDocuments?.data || []
                            }
                            encounterId={encounterId || ""}
                            form={form}
                            width="510px"
                            onDelete={(idDoc) =>
                              removeAdditionalDocument(idDoc)
                            }
                          />
                        </>
                      )}
                    </Box>
                  </HStack>
                </Box>
              </Box>
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    );

  return (
    <SummaryFile
      provider={provider}
      nursing={nursing}
      file={file}
      selectedValues={selectedValues}
    />
  );
}

type SummaryFileProps = {
  file?: Nullable<File | string | Blob>;
  nursing: Partial<
    Record<
      TriageChartCode,
      React.ReactElement<
        unknown,
        string | React.JSXElementConstructor<unknown>
      >[]
    >
  >;
  provider: Partial<
    Record<
      ProviderChartCode,
      React.ReactElement<
        unknown,
        string | React.JSXElementConstructor<unknown>
      >[]
    >
  >;
  selectedValues: string[];
};

function isPDFEmptyBySize(pdfBlob: Blob): boolean {
  const MIN_SIZE = 1024;
  return pdfBlob.size < MIN_SIZE;
}

function SummaryFile({
  file,
  selectedValues,
  provider,
  nursing,
}: SummaryFileProps) {
  const elementRefs = React.useRef<{
    [id: string]: { current: HTMLDivElement | null };
  }>({});

  const pdfRef = React.useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const setRef = (id: string) => (node: HTMLDivElement | null) => {
    if (!elementRefs.current[id]) {
      elementRefs.current[id] = { current: null };
    }
    if (node) {
      elementRefs.current[id].current = node;
    }
  };

  const toast = useToast();

  const { encounterId = "" } = useParams<ChartRouteBaseParams>();

  const { data: encounterInfo, isLoading: isEncounterInfoLoading } =
    useEncounterInfo(encounterId);

  const [pdfBlob, setPdfBlob] = React.useState<Blob | null>(null);

  const onGeneratePdf = React.useCallback(async () => {
    try {
      const style = document.createElement("style");
      document.head.appendChild(style);
      style.sheet?.insertRule(
        "body > div:last-child img { display: inline-block; }"
      );

      const pdfs = [];

      for (const [_, ref] of Object.entries(elementRefs.current)) {
        const pdf = await toPdf(ref, {
          method: "build",
          resolution: Resolution.MEDIUM,
        });
        pdfs.push(pdf);
      }

      const mergedPdf = await PDFDocument.create();
      const totalPages = pdfs.length;

      for (const [index, pdf] of pdfs.entries()) {
        if (pdf) {
          pdf.setFontSize(10);
          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();

          pdf.text(
            `Page ${index + 1} of ${totalPages}`,
            pageWidth / 2,
            pageHeight - 10,
            { align: "center" }
          );

          const pdfBytes = pdf.output("arraybuffer");
          const pdfDoc = await PDFDocument.load(pdfBytes);
          const copiedPages = await mergedPdf.copyPages(
            pdfDoc,
            pdfDoc.getPageIndices()
          );
          copiedPages.forEach((page) => mergedPdf.addPage(page));
        }
      }

      if (file && file instanceof Blob && !isPDFEmptyBySize(file)) {
        const finalMergedPdf = await PDFDocument.create();

        const existingPdfDoc = await PDFDocument.load(await file.arrayBuffer());

        const newPdfBytes = await mergedPdf.save();
        const newPdfDoc = await PDFDocument.load(newPdfBytes);

        const existingPages = await finalMergedPdf.copyPages(
          existingPdfDoc,
          existingPdfDoc.getPageIndices()
        );

        const newPages = await finalMergedPdf.copyPages(
          newPdfDoc,
          newPdfDoc.getPageIndices()
        );

        [...existingPages, ...newPages].forEach((page) =>
          finalMergedPdf.addPage(page)
        );

        setPdfBlob(
          new Blob([await finalMergedPdf.save()], { type: "application/pdf" })
        );
      } else {
        setPdfBlob(
          new Blob([await mergedPdf.save()], { type: "application/pdf" })
        );
      }

      style.remove();
    } catch (e) {
      toast({ description: "An error occurred while generating the PDF" });
    }
  }, [toast, elementRefs, file]);

  const onlyUseFile =
    !selectedValues.includes("addProviderChartSnapshots") &&
    !selectedValues.includes("addNursingChartSnapshots");

  const pendingElements = useIsFetching();

  const onClickPrint = React.useCallback(() => {
    let blobUrl = "";

    if (onlyUseFile && file instanceof Blob) {
      blobUrl = URL.createObjectURL(file);
    }

    if (!onlyUseFile && pdfBlob instanceof Blob) {
      blobUrl = URL.createObjectURL(pdfBlob);
    }

    if (!blobUrl) return;

    const fileName = `${encounterInfo?.data.lastName}_${
      encounterInfo?.data.firstName
    }_${format(
      dateWithoutTimezone(encounterInfo?.data.createdAt || ""),
      "MM-dd-yyyy"
    )}.pdf`;

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = fileName;
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(blobUrl), 60 * 1000);
  }, [
    onlyUseFile,
    file,
    pdfBlob,
    encounterInfo?.data.lastName,
    encounterInfo?.data.firstName,
    encounterInfo?.data.createdAt,
  ]);

  React.useEffect(() => {
    if (!isEncounterInfoLoading && pendingElements < 1 && !onlyUseFile) {
      onGeneratePdf();
    }
  }, [isEncounterInfoLoading, onGeneratePdf, pendingElements, onlyUseFile]);

  return (
    <ChartTrackingContextProvider>
      <Box maxH="100vh" overflow="auto">
        {!onlyUseFile && !pdfBlob && (
          <Center minH="100vh" flexDir="column" gap="0.5rem">
            <EmrLogoGray />
            <chakra.span fontSize="3xl" color="blue" fontWeight="bold">
              Generatig Report
            </chakra.span>
            {pendingElements > 0 ? (
              <chakra.span fontSize="xl" color="gray.700">
                Putting all the information in place: {pendingElements} pending
                requests
              </chakra.span>
            ) : (
              <chakra.span fontSize="xl" color="gray.700">
                We are now generating your report. This might take a couple
                minutes.
              </chakra.span>
            )}
            <Loading mt="1rem" />
          </Center>
        )}

        <SummaryPreviewFile
          file={onlyUseFile ? file : pdfBlob}
          rightElements={[
            <IconButton
              variant="ghost"
              key="searchButton"
              size="iconSm"
              aria-label="Print"
              height="40px"
              width="40px"
              padding="10px"
              disabled={!file || pendingElements > 0}
              onClick={onClickPrint}
              icon={
                <Icon
                  as={PrintOutline}
                  color="blue"
                  height="20px"
                  width="20px"
                />
              }
            />,
          ]}
          leftElements={[
            <Button
              key="backButton"
              size="iconSm"
              variant="ghost"
              color="blue"
              onClick={() => navigate("../")}
              leftIcon={<Icon as={SiChevronLeft} />}
            >
              Back
            </Button>,
          ]}
        />

        {/* {!pdfBlob && ( */}
        <VStack ref={pdfRef}>
          {selectedValues.includes("addProviderChartSnapshots") &&
            Object.entries(provider).map(
              ([chartCode, elements], chartIndex) => (
                <React.Fragment key={chartCode}>
                  {elements.map((element, index) => (
                    <Box
                      w="100%"
                      key={`${chartCode}-${chartIndex}-${index}`}
                      ref={setRef(
                        `provider-${chartCode}-${chartIndex}-${index}`
                      )}
                      display="flex"
                      flexDir="column"
                    >
                      <ChartSummaryHeader
                        encounterInfo={encounterInfo?.data}
                        chartName={
                          PROVIDER_CHARTS[chartCode as ProviderChartCode]
                        }
                      />
                      {element}
                    </Box>
                  ))}
                </React.Fragment>
              )
            )}

          {selectedValues.includes("addNursingChartSnapshots") &&
            Object.entries(nursing).map(([chartCode, elements], chartIndex) => (
              <React.Fragment key={chartCode}>
                {elements.map((element, index) => (
                  <Box
                    w="100%"
                    key={`${chartCode}-${chartIndex}-${index}`}
                    ref={setRef(`nursing-${chartCode}-${chartIndex}-${index}`)}
                    display="flex"
                    flexDir="column"
                  >
                    <ChartSummaryHeader
                      encounterInfo={encounterInfo?.data}
                      chartName={NURSE_CHARTS[chartCode as TriageChartCode]}
                    />
                    {element}
                  </Box>
                ))}
              </React.Fragment>
            ))}
        </VStack>
        {/* )} */}
      </Box>
    </ChartTrackingContextProvider>
  );
}

type SectionsSelectorProps = {
  onEditClick?: () => void;
  encounterId: string;
  form: UseFormReturn<SummaryForm>;
  sectionItems: SectionItemOption[];
  sectionUploadItems: SignedSectionItemOption[];
} & BoxProps;

function SectionsSelector(props: SectionsSelectorProps) {
  const {
    onEditClick,
    form,
    encounterId,
    sectionItems,
    sectionUploadItems,
    ...rest
  } = props;
  const { control } = form;

  return (
    <Box {...rest}>
      <ToolbarHeader
        height="0px"
        overflow="hidden"
        alignItems="center"
        rightButtons={[
          <Button key="doneBtn" onClick={() => onEditClick?.()}>
            Edit
          </Button>,
        ]}
      />
      <Card boxShadow="none" border="none">
        <Controller
          name="selectedSections"
          control={control}
          render={({ field }) => (
            <CheckboxListGroup
              divider={
                <Divider
                  width="calc(100% - 62px)"
                  marginLeft="62px"
                  height="0px"
                />
              }
              {...field}
            >
              {sectionItems.map(({ description, icon, value }) => (
                <CheckboxListItem
                  key={value}
                  value={value}
                  height="52px"
                  p="0"
                  passIsCheckedToChildren
                >
                  <SectionItem icon={icon} description={description} />
                </CheckboxListItem>
              ))}

              {sectionUploadItems.map(
                ({ description, icon, value, uploadedFile, type }) => (
                  <CheckboxListItem
                    key={value}
                    value={value}
                    p="0"
                    height="52px"
                    passIsCheckedToChildren
                    isReadOnly={!uploadedFile}
                  >
                    <SignedItem
                      description={description}
                      icon={icon}
                      uploadedFile={uploadedFile}
                      encounterId={encounterId}
                      type={type}
                    />
                  </CheckboxListItem>
                )
              )}
            </CheckboxListGroup>
          )}
        />
      </Card>
    </Box>
  );
}

type SectionsEditorProps = {
  onFinish?: () => void;
  onDelete?: (value: string) => void;
  encounterId: string;
  sectionItems: SectionItemOption[];
  sectionUploadItems: SignedSectionItemOption[];
} & BoxProps;

function SectionsEditor(props: SectionsEditorProps) {
  const {
    onFinish,
    encounterId,
    sectionItems,
    sectionUploadItems,
    onDelete,
    ...rest
  } = props;

  return (
    <Box p="0 15px" {...rest}>
      <ToolbarHeader
        p="0 15px"
        height="60px"
        alignItems="center"
        titleText={
          <chakra.span fontSize="0.9375rem" fontWeight="600">
            Sections
          </chakra.span>
        }
        leftButtons={[
          <Button key="cancelBtn" onClick={() => onFinish?.()}>
            Cancel
          </Button>,
        ]}
        rightButtons={[
          <Button key="doneBtn" onClick={() => onFinish?.()}>
            Done
          </Button>,
        ]}
      />
      <Card boxShadow="none" border="none">
        {sectionItems.map(({ key, description, value }) => (
          <React.Fragment key={key}>
            <SectionItemRemove
              encounterId={encounterId}
              description={description}
              value={value}
            />
            <Divider width="calc(100% - 62px)" marginLeft="62px" height="0px" />
          </React.Fragment>
        ))}

        {sectionUploadItems.map(
          ({ key, description, value, uploadedFile, type }, index) => (
            <React.Fragment key={key}>
              <SectionItemRemove
                encounterId={encounterId}
                description={description}
                value={value}
                uploadedFile={uploadedFile}
                type={type}
                onDeleteClick={(idDoc) => onDelete?.(idDoc)}
              />
              {!isLastIndex(index, sectionUploadItems) && (
                <Divider
                  width="calc(100% - 62px)"
                  marginLeft="62px"
                  height="0px"
                />
              )}
            </React.Fragment>
          )
        )}
      </Card>
    </Box>
  );
}

type AdditionalDocumentsProps = {
  onEditClick?: () => void;
  form: UseFormReturn<SummaryForm>;
  encounterId: string;
  additionalDocuments: {
    id: string;
    name: string;
  }[];
} & BoxProps;

function AdditionalDocumentsSelector(props: AdditionalDocumentsProps) {
  const { onEditClick, form, encounterId, additionalDocuments, ...rest } =
    props;
  const { control } = form;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <AdditionalDocumentModal
        isOpen={isOpen}
        onClose={onClose}
        encounterId={encounterId}
      />
      <Box {...rest} pb={4}>
        <ToolbarHeader
          height="60px"
          alignItems="center"
          titleText={
            <chakra.span fontSize="0.9375rem" fontWeight="600">
              Additional Documents
            </chakra.span>
          }
          rightButtons={[
            <IconButton
              key="addBtn"
              variant="ghost"
              size="iconSm"
              aria-label="Add file"
              height="40px"
              width="40px"
              padding="11px"
              icon={
                <Icon as={EmrPlus} color="blue" height="18px" width="18px" />
              }
              onClick={(e) => {
                onOpen();
                e.preventDefault();
              }}
            />,
          ]}
        />
        <Card boxShadow="none" border="none">
          <Controller
            name="miscFilesToAdd"
            control={control}
            render={({ field }) => (
              <CheckboxListGroup
                divider={
                  <Divider
                    width="calc(100% - 62px)"
                    marginLeft="62px"
                    height="0px"
                  />
                }
                {...field}
              >
                {additionalDocuments.map(({ name, id }) => (
                  <CheckboxListItem
                    key={id}
                    value={id}
                    height="52px"
                    p="0"
                    passIsCheckedToChildren
                  >
                    <SectionItem
                      description={name}
                      icon={
                        <Icon
                          h="18px"
                          w="18px"
                          color="gray.700"
                          as={DocumentOutline}
                        />
                      }
                    />
                  </CheckboxListItem>
                ))}
              </CheckboxListGroup>
            )}
          />
        </Card>
      </Box>
    </>
  );
}

type AdditionalDocumentsEditorProps = {
  onFinish?: () => void;
  onDelete?: (value: string) => void;
  form: UseFormReturn<SummaryForm>;
  encounterId: string;
  additionalDocuments: {
    id: string;
    name: string;
  }[];
} & BoxProps;

function AdditionalDocumentsEditor(props: AdditionalDocumentsEditorProps) {
  const {
    onFinish,
    form,
    encounterId,
    additionalDocuments,
    onDelete,
    ...rest
  } = props;
  const { control } = form;

  return (
    <Box p="0 15px" {...rest}>
      <ToolbarHeader
        p="0 15px"
        height="60px"
        alignItems="center"
        titleText={
          <chakra.span fontSize="0.9375rem" fontWeight="600">
            Additional Documents
          </chakra.span>
        }
        rightButtons={[
          <IconButton
            key="addBtn"
            variant="ghost"
            size="iconSm"
            aria-label="Add file"
            height="40px"
            width="40px"
            padding="11px"
            isDisabled
            icon={
              <Icon as={EmrPlus} color="gray.450" height="18px" width="18px" />
            }
          />,
        ]}
      />
      {additionalDocuments.length > 0 ? (
        <Card boxShadow="none" border="none">
          <Controller
            name="miscFilesToAdd"
            control={control}
            render={({ field }) => (
              <CheckboxListGroup
                divider={
                  <Divider
                    width="calc(100% - 62px)"
                    marginLeft="62px"
                    height="0px"
                  />
                }
                {...field}
              >
                {additionalDocuments.map(({ name, id }) => (
                  <CheckboxListItem key={id} value={id} p="0" height="52px">
                    <DocumentItem
                      encounterId={encounterId}
                      description={name}
                      value={id}
                      fileId={id}
                      onDeleteClick={(idDoc) => onDelete?.(idDoc)}
                    />
                  </CheckboxListItem>
                ))}
              </CheckboxListGroup>
            )}
          />
        </Card>
      ) : (
        <Box
          textAlign="center"
          fontSize="0.9375rem"
          fontWeight="600"
          color="gray.700"
        >
          No other documents have been added
        </Box>
      )}
    </Box>
  );
}

type SummaryPreviewFileProps = {
  file?: Nullable<File | string | Blob>;
  leftElements?: React.ReactNode[] | undefined;
  rightElements?: React.ReactNode[] | undefined;
};

function SummaryPreviewFile(props: SummaryPreviewFileProps) {
  const { file = null, leftElements, rightElements } = props;
  return (
    <PdfViewer value={file}>
      <PdfViewerContainer
        contentContainerProps={{ maxH: "92vh" }}
        header={
          <PdfViewerHeader
            title="Chart Summary"
            leftElements={leftElements}
            rightElements={rightElements}
          />
        }
        activePage={<PdfViewerActivePage />}
        preview={<PdfViewerPreview />}
        pageController={<PdfViewerPageController />}
      />
    </PdfViewer>
  );
}

type SummaryReasonModalProps = {
  isOpen: boolean;
  onChangeReason: (
    reason: SummaryReasons,
    description: string | undefined
  ) => void;
};

function SummaryReasonModal({
  isOpen,
  onChangeReason,
}: SummaryReasonModalProps) {
  const [selectedReason, setSelectedReason] = useState<
    SummaryReasons | undefined
  >(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);

  const reasons = enumMapper.getOptions("summaryReasons");

  const navigate = useNavigate();

  const warningDisclosure = useDisclosure();

  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent
        zIndex={1000}
        borderRadius="md"
        bgColor="gray.50"
        minHeight="480px"
        width="510px"
        maxWidth="510px"
      >
        <ModalHeader borderTopRadius="md">
          <Flex justifyContent="space-between" alignItems="center">
            <Button
              variant="label"
              color="blue"
              onClick={() => navigate("../")}
            >
              Cancel
            </Button>
            <Box>Medical Records</Box>
            <Button
              variant="label"
              color="blue"
              onClick={() => {
                if (
                  selectedReason === "SummaryReasonOther" &&
                  (description?.trim().length ?? 0) < 6
                ) {
                  warningDisclosure.onOpen();
                  return;
                }

                if (selectedReason) {
                  onChangeReason(selectedReason, description);
                }
              }}
              disabled={!selectedReason}
            >
              Continue
            </Button>
            <WarningDialog
              isOpen={warningDisclosure.isOpen}
              mainText="You must enter a reason to continue."
              title="Warning!"
              actionLabel="OK"
              onlyActionButton
              actionButtonProps={{ color: "blue" }}
              onCancel={warningDisclosure.onClose}
              onAction={warningDisclosure.onClose}
              onClose={warningDisclosure.onClose}
            />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box textAlign="center" pb={6}>
            Select the Reason you are accessing these <br /> Medical Records.
          </Box>
          <Box
            borderRadius="10px"
            bg="white"
            overflow="hidden"
            outline="none"
            minH="50px"
            mb={2}
          >
            {reasons.map((item, index) => (
              <>
                <Box
                  display="flex"
                  px={4}
                  py={4}
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => {
                    setSelectedReason(item.value);
                  }}
                  cursor="pointer"
                >
                  <Box fontWeight="600" pl={2}>
                    {item.label}
                  </Box>
                  {selectedReason === item.value && (
                    <Box h="24px" w="24px" pt={1}>
                      <Icon
                        h="14px"
                        w="14px"
                        as={EmrCheck}
                        color="blue"
                        verticalAlign="top"
                      />
                    </Box>
                  )}
                </Box>
                {!isLastIndex(index, reasons) && (
                  <Divider
                    width={`calc(100% - 20px)`}
                    ml="20px"
                    mt={1}
                    h="0.5px"
                  />
                )}
              </>
            ))}
          </Box>
          {selectedReason === "SummaryReasonOther" && (
            <Box
              borderRadius="10px"
              bg="white"
              overflow="hidden"
              outline="none"
              minH="50px"
              p={1}
              mt={2}
              mb={2}
            >
              <Textarea
                placeholder="Type other reason here..."
                size="sm"
                resize="none"
                onChange={(e) => {
                  setDescription(e.currentTarget.value);
                }}
              />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export { Summary };
