import {
  Box,
  Checkbox,
  CheckboxGroup,
  Divider,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  UseDisclosureReturn,
  Text,
} from "@chakra-ui/react";
import { EmrBed, EmrCalendar, EmrLock } from "@medstonetech/slate-icons";
import faker from "faker";
import { OrderElementResponse } from "modules/order-list/types";
import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Label } from "shared";
import { UseOrderFormFavoritesResponse } from "../api/queries/useOrderFormFavorites";
import { OrderItem } from "../types";
import {
  AddOrderItemSchedulerModalBody,
  initialSchedulerProps,
  SchedulerProps,
} from "./AddOrderItemSchedulerModal";
import { useForm } from "react-hook-form";

type AddOrderItemModalProps = {
  item: OrderElementResponse | null;
  onAddItem: (item: OrderItem) => void;
  favorite?: UseOrderFormFavoritesResponse;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">;

type AddOrderItemModalBodyProps = Omit<
  AddOrderItemModalProps,
  "isOpen" | "item"
> & {
  item: OrderElementResponse;
};

type ItemOrderForm = {
  description: string;
  notes: string;
  locations: string[];
};

const fieldLabelStyle = {
  mt: "10px",
  fontSize: "11pt",
};

function AddOrderItemModalBody({
  item,
  onClose,
  onAddItem,
}: AddOrderItemModalBodyProps) {
  const [isObservation, setIsObservation] = useState(false);
  const [showScheduler, setShowScheduler] = useState(false);
  const [schedulerProps, setSchedulerProps] = useState<SchedulerProps>(
    initialSchedulerProps
  );

  const form = useForm<ItemOrderForm>({
    mode: "onChange",
    defaultValues: {
      description: item?.description || "",
      notes: "",
      locations: [],
    },
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    setValue,
    register,
  } = form;

  const handleClose = () => {
    reset();
    onClose();
  };

  const { notes, description, locations } = watch();

  const onSubmit = () => {
    if (item) {
      onAddItem({
        id: faker.datatype.uuid(),
        description: description ?? item.description,
        orderElementType: item.orderFormElementType || "Lab",
        orderElementCategory: item.orderFormElementCategory,
        location: locations.join(", "),
        date: new Date(),
        notes,
        cptCode: item.cptCode,
        studyTypeId: item.examID,
        instructions: item.instructions,
        isObservation,
        ...(schedulerProps.isScheduled
          ? {
              prnReason: schedulerProps.prnReason,
              repetitions: schedulerProps.repetitions,
              repetitionHours: schedulerProps.repetitionHours,
              repetitionMinutes: schedulerProps.repetitionMinutes,
              scheduledTime: schedulerProps.scheduledTime?.toString() || "",
            }
          : null),
        isPRN: schedulerProps.isPRN,
        isScheduled: schedulerProps.isScheduled,
      });
    }
    handleClose();
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textareaRef.current && textareaRef.current.scrollHeight > 40) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight * 1.05
      }px`;
    }
  }, [item]);

  if (showScheduler) {
    return (
      <AddOrderItemSchedulerModalBody
        item={item}
        scheduleProps={schedulerProps}
        setShowScheduler={setShowScheduler}
        setSchedulerProps={setSchedulerProps}
      />
    );
  }

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      flex={1}
      display="flex"
      flexDirection="column"
    >
      <ModalBody
        display="flex"
        flexDirection="column"
        px="20px"
        py="20px"
        maxW="inherit"
      >
        <Box flex={1}>
          {item.orderFormElementType === "Radiology" && (
            <>
              <Label {...fieldLabelStyle}>Type *</Label>
              <InputGroup>
                <Input readOnly value={item?.orderFormElementCategory ?? ""} />
                <InputRightElement>
                  <Icon
                    as={EmrLock}
                    w="32px"
                    h="32px"
                    size="lg"
                    color="gray.450"
                  />
                </InputRightElement>
              </InputGroup>
            </>
          )}

          <Label {...fieldLabelStyle}>{"Description"} *</Label>
          <InputGroup>
            <Textarea
              {...register("description", {
                required: "Description is required",
              })}
              readOnly={item?.orderFormElementType !== "Lab"}
              resize="none"
              height="auto"
              rows={1}
              value={description}
              ref={textareaRef}
              onChange={(e) =>
                setValue("description", e.target.value, {
                  shouldValidate: true,
                })
              }
            />
            {item?.orderFormElementType !== "Lab" && (
              <InputRightElement>
                <Icon
                  as={EmrLock}
                  w="32px"
                  h="32px"
                  size="lg"
                  color="gray.450"
                />
              </InputRightElement>
            )}
          </InputGroup>
          {errors.description && (
            <Text color="red.500" fontSize="sm">
              {errors.description.message}
            </Text>
          )}

          {item?.orderFormElementType !== "Lab" &&
            !!item?.orderFormSubElements?.length && (
              <>
                <Label {...fieldLabelStyle}>
                  {"Locations"} (Choose at least one) *
                </Label>
                <Card variant="basic" py="8px">
                  <CheckboxGroup
                    value={locations}
                    onChange={(value: string[]) => setValue("locations", value)}
                  >
                    <Stack>
                      {item.orderFormSubElements.map((sub, i, arr) => (
                        <Fragment key={sub.id}>
                          <Checkbox value={sub.description} px="16px" py="4px">
                            {sub.description}
                          </Checkbox>
                          {i < arr.length - 1 && (
                            <Divider width="100%" h="0.5px" />
                          )}
                        </Fragment>
                      ))}
                    </Stack>
                  </CheckboxGroup>
                </Card>
              </>
            )}
          <Label {...fieldLabelStyle}>Notes</Label>
          <Textarea
            {...register("notes")}
            value={notes}
            onChange={(e) => setValue("notes", e.target.value)}
            resize="none"
            maxH="70px"
            minH="70px"
          />

          {(item.orderFormElementType === "Radiology" ||
            item.orderFormElementType === "Lab" ||
            item.orderFormElementType === "Medication") && (
            <HStack
              sx={{
                gap: "20px",
                mt: "20px",
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{
                  gap: "16px",
                  width: "50%",
                  borderRadius: "8px",
                }}
                variant="outlineSquared"
                colorScheme={schedulerProps.isScheduled ? "blue" : "gray"}
                onClick={() => setShowScheduler(true)}
              >
                <Icon as={EmrCalendar} w="24px" h="24px" /> Scheduled
              </Button>
              <Button
                sx={{
                  gap: "16px",
                  width: "50%",
                  borderRadius: "8px",
                }}
                variant="outlineSquared"
                colorScheme={isObservation ? "blue" : "gray"}
                onClick={() => setIsObservation(!isObservation)}
              >
                <Icon as={EmrBed} w="24px" h="24px" /> Observation Order
              </Button>
            </HStack>
          )}
        </Box>
      </ModalBody>
      <ModalFooter
        justifyContent="space-around"
        bgColor="white"
        borderBottomRadius="md"
        px={2}
      >
        <Button
          variant="outlineSquared"
          colorScheme="gray"
          onClick={() => {
            handleClose();
          }}
          width="240px"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="outlineSquared"
          bgColor="blue"
          color="white"
          disabled={!isValid}
          width="240px"
        >
          Add to Queue
        </Button>
      </ModalFooter>
    </Box>
  );
}

function AddOrderItemModal({
  isOpen,
  onClose,
  item,
  onAddItem,
  favorite,
}: AddOrderItemModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        w="660px"
        maxWidth="660px"
        height={item?.orderFormElementType === "Radiology" ? "610px" : "480px"}
        bg="gray.200"
      >
        <ModalHeader textAlign="center" fontSize="17pt">
          {item?.orderFormElementType} Order
        </ModalHeader>
        {isOpen && item && (
          <AddOrderItemModalBody
            onAddItem={onAddItem}
            onClose={onClose}
            item={item}
            favorite={favorite}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { AddOrderItemModal };
