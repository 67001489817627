import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  useControllableState,
} from "@chakra-ui/react";
import { usePermissions } from "contexts";
import {
  useActiveCharts,
  UseActiveChartsResponseItem,
} from "modules/charts-shared";
import * as React from "react";
import { useParams } from "react-router-dom";
import { Loading, SidebarNavigation } from "shared";
import { ChartType } from "types";
import { isLastIndex } from "utils";
import { ChartPickerNavigationElement } from "./ChartPickerNavigationElement";

type ChartPickerProps = {
  isEditMode?: boolean;
  defaultIsEditMode?: boolean;
  setIsEditMode?: (value: boolean) => void;
  setHasActiveCharts?: (value: boolean) => void;
};

function ChartPicker(props: ChartPickerProps) {
  const {
    defaultIsEditMode = false,
    isEditMode: isEditModeProp,
    setIsEditMode: setIsEditModeProp,
    setHasActiveCharts,
  } = props;
  const { scope } = usePermissions();

  const [isEditMode, setIsEditMode] = useControllableState({
    value: isEditModeProp,
    defaultValue: defaultIsEditMode,
    onChange: setIsEditModeProp,
  });
  const { encounterId = "" } = useParams<{ encounterId: string }>();
  const { data, isLoading, error } = useActiveCharts(encounterId);
  const showError = !isLoading && !!error;
  const showLoading = isLoading && !data;
  const mainRoute = window.location.hash.split("/")[1];

  const getObservationNavigationProps = (
    chartCode: string,
    chartType?: ChartType
  ) => {
    if (chartCode === "C070a")
      return {
        colorScheme: "indigo",
        to: `/${mainRoute}/charts/${encounterId}/Chart/${chartCode}`,
      };
    if (chartType === "Triage") {
      return {
        colorScheme: "red-sizzling",
      };
    }
    return {
      colorScheme: "blue",
    };
  };

  const chartSections = React.useMemo(
    () =>
      (data?.data || []).reduce<{
        nurse: UseActiveChartsResponseItem[];
        provider: UseActiveChartsResponseItem[];
        other: UseActiveChartsResponseItem[];
      }>(
        (result, chart, index) => {
          if (chart.chartType === "Triage") result.nurse.push(chart);
          else if (chart.chartType === "Chart") result.provider.push(chart);
          else result.other.push(chart);

          if (isLastIndex(index, data?.data || []))
            result.provider = [...result.provider, ...result.other];
          return result;
        },
        { nurse: [], provider: [], other: [] }
      ),
    [data]
  );

  const getChartNavgationItem = ({
    chartApplicationId,
    chartTitle,
    chartType,
    chartCode,
  }: UseActiveChartsResponseItem) => (
    <ChartPickerNavigationElement
      key={chartApplicationId}
      chartName={chartTitle}
      chartType={chartType}
      chartCode={chartCode}
      isEditMode={isEditMode}
      encounterId={encounterId}
      to={`/${mainRoute}/charts/${encounterId}/${chartType}/${chartCode}`}
      {...getObservationNavigationProps(chartCode, chartType)}
    />
  );

  const EditButton = () => {
    return !isEditMode ? (
      <Button
        variant="label"
        color="blue"
        onClick={() => {
          setIsEditMode(true);
          setHasActiveCharts?.(Boolean(data?.data.length));
        }}
      >
        Edit
      </Button>
    ) : (
      <Button
        variant="label"
        marginRight="5px"
        color="blue"
        onClick={() => setIsEditMode(false)}
      >
        Done
      </Button>
    );
  };

  const addDeletePermission =
    scope("nurse:add").isAccessible ||
    scope("provider:add").isAccessible ||
    scope("provider:delete").isAccessible ||
    scope("provider:delete").isAccessible;

  return (
    <>
      <Flex justifyContent="space-between" width="100%" marginBottom="18px">
        <Heading fontSize="1.0625rem">Charts</Heading>
        {addDeletePermission && <EditButton />}
      </Flex>
      <Box
        style={{ scrollbarGutter: "auto" }}
        overflowX="hidden"
        maxHeight="45%"
      >
        <SidebarNavigation>
          {chartSections.nurse.length > 0 && (
            <>
              <Heading as="h2" fontSize="0.9375rem" pl="8px" mb="10px">
                Nursing
              </Heading>
              {chartSections.nurse.map(getChartNavgationItem)}
              {chartSections.provider.length > 0 && (
                <Divider borderColor="gray.500" m="10px 0" />
              )}
            </>
          )}
          {chartSections.provider.length > 0 && (
            <>
              <Heading as="h2" fontSize="0.9375rem" pl="8px" mb="10px">
                Provider
              </Heading>
              {chartSections.provider.map(getChartNavgationItem)}
            </>
          )}
          {showLoading && <Loading />}
          {showError && "Something went wrong."}
        </SidebarNavigation>
      </Box>
    </>
  );
}

export type { ChartPickerProps };
export { ChartPicker };
