import * as React from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  Box,
  BoxProps,
  Collapse,
  HStack,
  Icon,
  IconButton,
  Stack,
  StackProps,
  Text,
  TextProps,
  chakra,
} from "@chakra-ui/react";
import { Loading } from "shared/loading";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
} from "modules/charts-shared/components";
import { ChartForm, useChartForm, useEncounterDNRFile } from "modules";
import { usePermissions } from "contexts";
import { ChartForm as ChartFormWrapper } from "modules/charts-shared-v2";
import {
  Card,
  PdfViewer,
  PdfViewerActions,
  PdfViewerActivePage,
  PdfViewerContainer,
  PdfViewerHeader,
  PdfViewerPreview,
  StaffAvatar,
} from "shared";
import { format } from "date-fns";
import { Dialog, Expand } from "icons";
import { useGetUserDetails } from "api";
import { USERID_CLAIMS } from "system-constants";
import { useAuth0 } from "@auth0/auth0-react";
import { EmrProfileComplete } from "@medstonetech/slate-icons";

const sectionId = "SCHC";
const chartCode = "T070a";

const titleProps: TextProps = {
  fontSize: "18px",
  fontWeight: "600",
  mb: 3,
};

const GRID_PROPS: BoxProps = {
  padding: "1rem .5rem",
  display: "grid",
  gridTemplateColumns: "0.75fr 3fr 10fr",
  gridColumnGap: "1rem",
  alignItems: "center",
  justifyItems: "center",
};

function ChiefComplaintDataContainer(
  props: StackProps & { useFormContext: UseFormReturn<ChartForm> }
) {
  const { user } = useAuth0();
  const { watch, setValue } = props.useFormContext;

  const userId = watch(`Q001.${chartCode}${sectionId}userId`);
  const { data: userDetails } = useGetUserDetails(userId as string);

  const setPicture = React.useCallback(() => {
    setValue(`Q001.${chartCode}${sectionId}userId`, user?.[USERID_CLAIMS], {
      shouldDirty: true,
    });
  }, [setValue, user]);

  return (
    <Box {...props} display="flex" flexDir="column" gap={6}>
      <HStack gap={4}>
        <Box>
          <Text {...titleProps}>Obs. Date/Time</Text>
          <Card
            variant="default"
            overflow="hidden"
            h="66px"
            w="200px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize="17px"
            fontWeight="600"
          >
            {format(new Date(), "MM/dd/yy h:mm")}
          </Card>
        </Box>
        <Box>
          <Text {...titleProps}>Code Status</Text>
          <Card
            variant="default"
            overflow="hidden"
            h="66px"
            w="200px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              w="170px"
              h="36px"
              bgColor="green"
              color="white"
              borderRadius="10px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontSize="15px"
              fontWeight="700"
            >
              None
            </Box>
          </Card>
        </Box>
      </HStack>
      <Box>
        <Text {...titleProps}>Admitting Diagnosis</Text>
        <Card
          variant="default"
          overflow="hidden"
          h="200px"
          w="850px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={4}
        >
          <ChartRow>
            <ChartRowElement
              name={`Q001.${chartCode}${sectionId}Q001A001`}
              type="input"
              h="170px"
            />
          </ChartRow>
        </Card>
      </Box>
      <Box>
        <Text {...titleProps}>Isolation Status</Text>

        <Box borderRadius="10px">
          <Card
            bg="gray.250"
            boxShadow="none"
            borderBottomRadius="unset"
            {...GRID_PROPS}
          >
            <Box>
              <Icon
                as={EmrProfileComplete}
                color="gray.650"
                mt="5px"
                w="32px"
                h="auto"
              />
            </Box>
            <chakra.span>Date / Time</chakra.span>
            <Box
              display="grid"
              gridTemplateColumns="1fr"
              width="100%"
              justifyItems="center"
            >
              <chakra.span>Description</chakra.span>
            </Box>
          </Card>
          <Box bg="white">
            <Box {...GRID_PROPS}>
              <StaffAvatar
                size="xs"
                fontSize=".75rem"
                userName={userDetails?.data?.fullName}
                profileUrl={userDetails?.data?.pictureUrl}
              />
              <chakra.span>
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  type="date"
                  w="170px"
                  onClick={setPicture}
                />
              </chakra.span>
              <Box display="flex" width="100%" justifyItems="center">
                <ChartRowElement
                  label="respiratory"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  type="cross-option"
                />
                <ChartRowElement
                  label="negative vent"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  type="cross-option"
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  type="input"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

type ShowFileProps = {
  encounterId: string;
} & BoxProps;

function DNRFile(props: ShowFileProps) {
  const { encounterId, ...rest } = props;
  const [file, setFile] = React.useState<Nullable<File | string | Blob>>();
  const [isFileMode, setFileMode] = React.useState(false);
  const { data, isLoading } = useEncounterDNRFile(encounterId);

  React.useEffect(() => {
    if (data) {
      setFile(data.data);
    }
  }, [data]);

  return (
    <Box margin="auto" textAlign="center" h="100%" {...rest}>
      <PdfViewer value={file}>
        <PdfViewerContainer
          height="100%"
          activePage={
            <PdfViewerActivePage pageWidth={isFileMode ? 1200 : 800} />
          }
          header={
            <PdfViewerHeader
              title="DNR"
              leftElements={[
                <IconButton
                  key="expand-collapse-button"
                  aria-label="submit dnr"
                  icon={<Icon as={isFileMode ? Collapse : Expand} />}
                  color="blue"
                  variant="icon"
                  onClick={() => setFileMode((prev) => !prev)}
                />,
              ]}
              rightElements={[
                <PdfViewerActions
                  key="viewer-actions"
                  isSubmitLoading={isLoading}
                  showSubmit
                  showDelete
                />,
              ]}
            />
          }
          preview={isFileMode && <PdfViewerPreview />}
        />
      </PdfViewer>
    </Box>
  );
}

function N70ChiefComplaint() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Chief Complaint",
    chartCode: chartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:cc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  const gapSize = "20px";

  return (
    <FormProvider {...useFormContext}>
      <ChartFormWrapper
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={Dialog} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Chief Complaint {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <Box height="100vh" overflow="auto" padding="20px">
          <Stack direction={{ base: "column", xl: "row" }} spacing={gapSize}>
            <ChiefComplaintDataContainer
              flex={1}
              useFormContext={useFormContext}
            />
            <DNRFile
              encounterId={encounterId}
              flex={{ xl: "0.8" }}
              overflow="hidden"
            />
          </Stack>
        </Box>
      </ChartFormWrapper>
    </FormProvider>
  );
}

export { N70ChiefComplaint };
