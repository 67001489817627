import * as React from "react";
import {
  Badge,
  Box,
  Divider,
  Flex,
  Icon,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Button, Card, Label, Loading, WarningDialog } from "shared";

import {
  HARD_STOPS_TRIAGE_SECTIONS,
  HARD_STOPS_PROVIDER_SECTIONS,
  HARD_STOPS_SUBSECTIONS,
  HARD_STOPS_SECTIONS_ROUTES,
} from "./constants";
import { ProviderHardStopsResponse, TriageHardStopsResponse } from "./types";
import { useNavigate, useParams } from "react-router-dom";
import { CheckCircleHardStops, ExclamationCircleHardStops } from "icons";
import {
  UseChartHardStopsResponse,
  useUpdateChartApplicationStatus,
} from "modules/charts-shared/api";
import { ChartRouteBaseParams } from "modules/charts-shared/types";
import { useFormContext } from "react-hook-form";
import { formMessages } from "messages";
import { extractApiErrorMessage } from "utils";
import { ChartType } from "types";
import { ProviderChartCode, TriageChartCode } from "enums";
import { usePermissions } from "contexts";

const SECTION_CODE = "SDIS";

type HardStops =
  | TriageHardStopsResponse
  | ProviderHardStopsResponse
  | undefined;

type SidebarHardStopProps = {
  isLoading: boolean;
  patientDispositioned: boolean;
  chartCode: TriageChartCode | ProviderChartCode;
  chartType: ChartType;
  data: UseChartHardStopsResponse | undefined;
};

type ChartHardStopsSectionProps = {
  key: number;
  sections:
    | typeof HARD_STOPS_PROVIDER_SECTIONS
    | typeof HARD_STOPS_TRIAGE_SECTIONS;
  section:
    | keyof typeof HARD_STOPS_PROVIDER_SECTIONS
    | keyof typeof HARD_STOPS_TRIAGE_SECTIONS;
  data: HardStops;
  showGoToLabel?: boolean;
};

const validateElementRestrictions = (section: string, data: HardStops) => {
  const restrictions = {
    medsAndIV: data?.hasMedAndIvOrders,
    ekg: data?.hasEKGOrders,
    labs: data?.hasLabsOrders,
    treatments: data?.hasTreatmentsOrders,
    radiology: data?.hasRadiologyOrders,
  };

  const prop = section as keyof typeof restrictions;
  return restrictions[prop] ?? true;
};

function ChartHardStopsSection(props: ChartHardStopsSectionProps) {
  const { key, section, sections, data, showGoToLabel = false } = props;
  const element = data ? data[section as keyof typeof data] : undefined;

  const navigate = useNavigate();

  if (
    typeof element === "undefined" ||
    !validateElementRestrictions(section, data)
  )
    return null;

  return (
    <Box key={key}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Label ml="25px" fontWeight="500" color="black">
          {sections[section as keyof typeof sections]}
        </Label>

        {showGoToLabel && (
          <Button
            variant="label"
            onClick={() =>
              navigate(
                `./../${
                  HARD_STOPS_SECTIONS_ROUTES[
                    section as keyof typeof HARD_STOPS_SECTIONS_ROUTES
                  ]
                }`
              )
            }
            color="blue"
          >
            Go to Section
          </Button>
        )}
      </Box>

      <Card
        sx={{
          width: "99%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: 1.5,
          px: 5,
          flexDirection: "column",
          boxShadow: "none",
        }}
      >
        {Object.keys(element).map((prop, index) => {
          const value = element[prop as keyof typeof element];
          if (typeof value === "undefined") return null;

          return (
            <Flex w="100%" columnGap="17px" alignItems="center" key={index}>
              <Icon
                as={value ? CheckCircleHardStops : ExclamationCircleHardStops}
                alignSelf="center"
                fontSize={"26px"}
                color={value ? "green" : "red"}
              />
              <Flex
                borderBottom={
                  index === Object.keys(element).length - 1
                    ? "0px"
                    : "1px solid"
                }
                borderColor="gray.450"
                w="100%"
                justify="start"
                p="1rem 0 1rem 1rem"
                alignItems="center"
                fontWeight="500"
              >
                {
                  HARD_STOPS_SUBSECTIONS[
                    prop as keyof typeof HARD_STOPS_SUBSECTIONS
                  ]
                }
              </Flex>
            </Flex>
          );
        })}
      </Card>
    </Box>
  );
}

function SidebarHardStops(props: SidebarHardStopProps) {
  const { data, chartCode, chartType, isLoading, patientDispositioned } = props;
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const toast = useToast();
  const { scope } = usePermissions();
  const { getValues } = useFormContext();

  const chartStatus = getValues("chartStatus");

  const [warningDialogMessage, setWarningDialogMessage] = React.useState("");
  const [warningDialogSubMessage, setWarningDialogSubMessage] =
    React.useState("");
  const [warningDialogAction, setWarningDialogAction] =
    React.useState<() => void>();

  const pendingHardStops = React.useMemo(() => {
    const hardStopsData =
      chartType === "Chart" ? data?.providerHardStops : data?.triageHardStops;

    if (!hardStopsData) return 0;

    const {
      hasEKGOrders,
      hasLabsOrders,
      hasMedAndIvOrders,
      hasRadiologyOrders,
      hasTreatmentsOrders,
      ...rest
    } = hardStopsData;

    return Object.entries(rest).reduce((count, [_key, value]) => {
      if (!validateElementRestrictions(_key, hardStopsData)) return count;

      return count + Object.values(value).filter((v) => !v).length;
    }, 0);
  }, [chartType, data]);

  const areAllHardStopsTrue = React.useMemo(() => {
    const hardStopsData =
      chartType === "Chart" ? data?.providerHardStops : data?.triageHardStops;

    if (!hardStopsData) return false;

    // Ignore this properties when validating
    const {
      hasEKGOrders,
      hasLabsOrders,
      hasMedAndIvOrders,
      hasRadiologyOrders,
      hasTreatmentsOrders,
      ...rest
    } = hardStopsData;

    return Object.entries(rest).every(([_key, value]) => {
      if (!validateElementRestrictions(_key, hardStopsData)) return true;

      return Object.values(value).every((v) => !!v);
    });
  }, [chartType, data]);

  const {
    mutateAsync: updateChartStatus,
    isLoading: isLoadingChartStatusUpdate,
  } = useUpdateChartApplicationStatus({
    encounterId,
    chartCode,
    sectionId: SECTION_CODE,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleChangeChartStatusToComplete = async () => {
    try {
      await updateChartStatus("Completed");
      toast({ description: formMessages.updateSuccess("ChartStatus") });

      onCloseWarningDialog();
    } catch (error) {
      toast({ status: "error", description: extractApiErrorMessage(error) });
    }
  };

  const onCompleteChartAction = () => {
    if (!areAllHardStopsTrue && scope("provider:compchart").isAccessible) {
      setWarningDialogMessage(
        "Are you sure you want to mark this Chart as Complete?"
      );
      setWarningDialogSubMessage(
        "There are pending hard stops. override and mark as complete?"
      );
    } else {
      setWarningDialogMessage(
        "Are you sure you want to mark this Chart as Complete?"
      );
      setWarningDialogSubMessage("This action cannot be undone.");
    }
    setWarningDialogAction(() => handleChangeChartStatusToComplete);
    onOpen();
  };

  const onCloseWarningDialog = () => {
    setWarningDialogMessage("");
    setWarningDialogAction(undefined);
    onClose();
  };

  const sections:
    | typeof HARD_STOPS_PROVIDER_SECTIONS
    | typeof HARD_STOPS_TRIAGE_SECTIONS =
    chartType === "Triage"
      ? HARD_STOPS_TRIAGE_SECTIONS
      : HARD_STOPS_PROVIDER_SECTIONS;

  return (
    <Box
      width="25vw"
      bg="gray.200"
      height="100%"
      position="fixed"
      top="0"
      right="0"
      display="flex"
      flexDirection="column"
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          backgroundColor: "gray.200",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Text
            sx={{
              paddingLeft: "15px",
              fontWeight: "600",
              fontSize: "1.2625rem",
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {chartType === "Triage" ? "Nurse" : "Provider"} Chart Hard Stops
          </Text>
          <Badge
            bg="white"
            color="red"
            fontWeight="bold"
            borderRadius="10px"
            padding="0.325rem 0.8rem"
          >
            {isLoading ? "-" : pendingHardStops}
          </Badge>
        </Box>
        <Divider />
      </Box>

      <Box
        sx={{
          display: "flex",
          padding: "20px",
          height: "90%",
          overflowY: "auto",
          flexDirection: "column",
          justifyContent: isLoading ? "center" : "flex-start",
          backgroundColor: "gray.200",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            Object.keys(sections).map((key, index) => (
              <ChartHardStopsSection
                key={index}
                showGoToLabel
                sections={sections}
                section={key as keyof typeof HARD_STOPS_PROVIDER_SECTIONS}
                data={
                  chartType === "Chart"
                    ? data?.providerHardStops
                    : data?.triageHardStops
                }
              />
            ))
          )}
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "white",
        }}
      >
        <Divider />
        <Flex justifyContent="center" width="100%" pt={2} pb={2} gap={30}>
          <Badge
            bg="transparent"
            color="red"
            borderRadius="20px"
            padding="0.325rem .85rem"
            fontSize=".875rem"
            textTransform="capitalize"
          >
            {patientDispositioned
              ? "Patient Dispositioned"
              : "Patient Not Dispositioned"}
          </Badge>
        </Flex>
        <Box
          sx={{
            backgroundColor: "gray.400",
          }}
        >
          <Flex justifyContent="center" width="100%" pt={4} pb={4} gap={30}>
            <Button
              variant="outlineSquared"
              borderRadius="25px"
              width="320px"
              isDisabled={
                scope("provider:compchart").isAccessible
                  ? isLoadingChartStatusUpdate || chartStatus === "Completed"
                  : !scope(
                      chartType === "Chart" ? "provider:disp" : "nurse:disp"
                    ).isEditable ||
                    isLoadingChartStatusUpdate ||
                    chartStatus === "Completed" ||
                    areAllHardStopsTrue === false
              }
              onClick={onCompleteChartAction}
              isLoading={isLoadingChartStatusUpdate}
            >
              Chart Complete
            </Button>

            <WarningDialog
              isOpen={isOpen}
              onCancel={onCloseWarningDialog}
              title="Warning"
              mainText={warningDialogMessage}
              secondaryText={warningDialogSubMessage}
              onClose={onCloseWarningDialog}
              onAction={() => warningDialogAction && warningDialogAction()}
              cancelLabel="No"
              actionLabel="Yes"
              blockScrollOnMount={false}
              cancelButtonProps={{ color: "red" }}
              actionButtonProps={{ color: "blue" }}
            />
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}

export type { HardStops, SidebarHardStopProps, ChartHardStopsSectionProps };
export { SidebarHardStops, ChartHardStopsSection, validateElementRestrictions };
