import * as React from "react";
import {
  Badge,
  Box,
  BoxProps,
  Icon,
  chakra,
  useDisclosure,
  HStack,
  Text,
  Flex,
  Image,
  VStack,
} from "@chakra-ui/react";
import {
  EmrForm,
  EmrProfileComplete,
  EmrSignature,
} from "@medstonetech/slate-icons";
import { EditSquared, RightArrow } from "icons";
import { ChartRouteBaseParams, ChartRowElement } from "modules/charts-shared";
import { ORDER_STATUS_COLOR } from "modules/orders";
import {
  useEncounterOrderFile,
  useEncounterOrderFileRamsoft,
} from "modules/orders/api";
import {} from "modules/provider/api";
import { useLocation, useParams } from "react-router-dom";
import { Avatar, Button, Card, StaffAvatar, Tooltip } from "shared";
import { enumMapper } from "utils";
import { RadiologyChartFileWrapper } from "./RadiologyChartFile";
import { RadiologyInterpretations } from "./RadiologyInterpretations";
import {
  DEFAULT_RADIOLOGY_INTERPRETATION,
  RADIOLOGY_INTERPRETATIONS_SELECT_ITEM,
} from "./constants";
import { InterpretationElements } from "./InterpretationElements";
import {
  RadiologyChartTableProps,
  RadiologyInterpretation,
  RadiologyItemProps,
  RadSection,
} from "./types";
import { ToggableChartWrappers } from "../radiology-charts";
import { useFormContext } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";

import faker from "faker";
import { useGetUserSignature } from "modules/onboarding/api";
import { useAuth0 } from "@auth0/auth0-react";
import { USERID_CLAIMS } from "system-constants";
import { useUserRoles } from "contexts/UserRoles";
import { UserType } from "modules/identity";

const GRID_PROPS: BoxProps = {
  padding: "1rem 1.5rem",
  textAlign: "center",
  display: "grid",
  gridTemplateColumns: "5fr 10fr 8fr 20fr 25fr 6fr 6fr 10fr 3fr",
  gridColumnGap: "1rem",
  alignItems: "center",
};

function RadiologyHeaders() {
  return (
    <Card bg="gray.250" marginBottom="1rem" boxShadow="none" {...GRID_PROPS}>
      <chakra.span>
        <Icon
          as={EmrProfileComplete}
          w="1.25rem"
          h="1.25rem"
          color="gray.650"
        />
      </chakra.span>
      <chakra.span>Date / Time</chakra.span>
      <chakra.span textAlign="center">Order ID</chakra.span>
      <chakra.span textAlign="left">Order</chakra.span>
      <chakra.span textAlign="left">Order Notes</chakra.span>
      <chakra.span>Report</chakra.span>
      <chakra.span>Interpretation</chakra.span>
      <chakra.span>Status</chakra.span>
      <chakra.span></chakra.span>
    </Card>
  );
}

function RadiologyItemSignature(props: { index: number }) {
  const { index } = props;

  const { watch, setValue } = useFormContext();

  const signedBy = watch(`rad.${index}.signedBy`);

  const { data: signature } = useGetUserSignature(signedBy || "");

  const { user } = useAuth0();

  const handleSign = () =>
    setValue(`rad.${index}.signedBy`, user?.[USERID_CLAIMS], {
      shouldDirty: true,
    });

  const { roles } = useUserRoles();

  const isAllowedToSign = React.useMemo(() => {
    const rolesAllowedToViewOpenChartCounter: UserType[] = [
      "Administrator",
      "SupervisingProvider",
      "Provider",
    ];

    return roles.some((x) => rolesAllowedToViewOpenChartCounter.includes(x));
  }, [roles]);

  return (
    <Box height="100px" p={6} width="100%" bgColor="white" borderRadius={20}>
      <HStack width="100%" spacing={6}>
        <HStack display="flex" justifyContent="start" spacing={4} flex={2}>
          <Text fontWeight="600" fontSize="18px">
            Provider Signature
          </Text>
        </HStack>
        {!!signedBy ? (
          <Flex justifyContent="center" flex={1}>
            <HStack>
              <StaffAvatar
                w="40px"
                h="40px"
                size="xs"
                fontSize=".75rem"
                userName={signature?.data?.fullName}
                profileUrl={signature?.data?.pictureUrl}
              />
              <VStack spacing="0" alignItems="flex-start">
                <Box
                  fontSize="1.0625rem"
                  fontWeight="600"
                  lineHeight="1.5rem"
                  color="black"
                >
                  {signature?.data?.fullName}
                </Box>
                <Box
                  fontSize="0.875rem"
                  fontWeight="400"
                  lineHeight="1rem"
                  color="gray.700"
                >
                  {signature?.data?.teams}
                </Box>
              </VStack>
            </HStack>
          </Flex>
        ) : (
          <Text>No signature added</Text>
        )}
        <HStack
          pl={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={40}
          flex={2}
        >
          <HStack spacing={10}>
            {!!signedBy ? (
              <>
                <Text fontWeight="600" fontSize="16px" color="gray.700">
                  E-signed by:
                </Text>
                <Box minW={300} maxW={400} minH={50}>
                  <Image
                    src={signature?.data?.signature}
                    height="100%"
                    width="100%"
                  />
                </Box>
              </>
            ) : (
              <Tooltip
                isOpen={!isAllowedToSign}
                label="Only Providers are allowed to sign"
              >
                <Button
                  variant="label"
                  color="blue"
                  onClick={handleSign}
                  disabled={!isAllowedToSign}
                >
                  <Icon
                    as={EmrSignature}
                    fontSize="1.5rem"
                    color="blue"
                    m={2}
                  />
                  Add Signature
                </Button>
              </Tooltip>
            )}
          </HStack>
        </HStack>
      </HStack>
    </Box>
  );
}

function RadiologyItem({
  index,
  item,
  isReadOnly,
  chartCode,
  sectionId,
  sectionCode,
  hideInterpretations = false,
}: RadiologyItemProps) {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();

  const { watch, setValue } = useFormContext();
  const location = useLocation();

  const [isFileMode, setFileMode] = useState(false);
  const fromSummary = location.pathname.includes("medical-records");

  const { isOpen, onToggle } = useDisclosure();

  const rad: RadSection = watch(`rad.${index}`);
  const orderId = rad.order?.id;
  const hasInterpretation = useMemo(() => {
    return rad
      ? Object.keys(rad)
          .filter((x) => x.includes(chartCode))
          .some((x) => Boolean(rad[x]))
      : false;
  }, [rad, chartCode]);

  const { data: fileOrder } = useEncounterOrderFile(item.order?.id ?? "");
  const { data: fileRamsoft } = useEncounterOrderFileRamsoft(
    item.order?.id ?? ""
  );

  const onAddInterpretation = (
    list: RADIOLOGY_INTERPRETATIONS_SELECT_ITEM[]
  ) => {
    setValue(
      `rad.${index}.interpretations`,
      [
        ...(rad.interpretations || []),
        {
          ...DEFAULT_RADIOLOGY_INTERPRETATION,
          type: list[0].type,
          code: list[0].value,
          sectionId: list[0].id,
        },
      ],
      { shouldDirty: true }
    );
  };

  const onToggleInterpretation = (interpretation: RadiologyInterpretation) => {
    const interpretationExists = (rad.interpretations || []).find(
      (el) => el.sectionId === interpretation.sectionId
    );
    if (interpretationExists) {
      interpretationExists.deleted = !interpretationExists.deleted;
    }

    setValue(
      `rad.${index}.interpretations`,
      [...(rad.interpretations || []), interpretation],
      { shouldDirty: true }
    );
  };

  const onRemoveInterpretation = (id: string) => {
    (rad.interpretations || []).forEach(
      (interpretation: RadiologyInterpretation) => {
        if (interpretation.sectionId === id) {
          interpretation.deleted = true;
        }
      }
    );

    setValue(`rad.${index}.interpretations`, rad.interpretations || [], {
      shouldDirty: true,
    });
  };

  // This default interpretation is used to store the values that are outside of the collapsible section
  const defaultInterpretation = useMemo(() => {
    return (
      rad?.interpretations?.find((el) => el.type === "Default") ?? {
        ...DEFAULT_RADIOLOGY_INTERPRETATION,
        sectionId: faker.datatype.uuid(),
      }
    );
  }, [rad]);

  const date = watch(
    `rad.${index}.${chartCode}${defaultInterpretation.sectionId}${sectionCode}${defaultInterpretation.code}Q001A001`
  );
  const setDateValue = React.useCallback(() => {
    if (!date)
      setValue(
        `rad.${index}.${chartCode}${defaultInterpretation.sectionId}${sectionCode}${defaultInterpretation.code}Q001A001`,
        new Date().toISOString()
      );
  }, [date, index, chartCode, defaultInterpretation, sectionCode, setValue]);

  const onExpand = () => {
    onToggle();
    setDateValue();
  };

  // Always ensure that the default interpretation is present in the array
  useEffect(() => {
    const interpretationExists = (rad.interpretations || []).find(
      (el) => el.sectionId === defaultInterpretation.sectionId
    );
    if (!interpretationExists) {
      setValue(`rad.${index}.interpretations`, [
        ...((rad.interpretations || []).filter(
          (el) => el.type !== "Default" && !el.deleted
        ) || []),
        defaultInterpretation,
      ]);
    }
  }, [defaultInterpretation, index, rad, setValue]);

  return (
    <Box>
      <Card boxShadow="none">
        <Box {...GRID_PROPS}>
          <Avatar
            src={""}
            width="1.75rem"
            height="1.75rem"
            justifySelf="center"
          />
          <chakra.span>
            <ChartRowElement
              isDisabled={isReadOnly}
              type="date"
              name={`rad.${index}.${chartCode}${defaultInterpretation.sectionId}${sectionCode}${defaultInterpretation.code}Q001A001`}
            />
          </chakra.span>
          <chakra.span textAlign="center">{item.order?.orderId}</chakra.span>
          <chakra.span textAlign="left">{item.order?.description}</chakra.span>
          <chakra.span textAlign="left">{item.order?.notes}</chakra.span>
          <Icon
            as={EmrForm}
            fontSize="1.5rem"
            color={Boolean(fileOrder || fileRamsoft) ? "green" : "gray.400"}
            onClick={isOpen ? undefined : onExpand}
            cursor="pointer"
            sx={{
              position: "relative",
              left: "50px",
            }}
          />
          <Icon
            as={EditSquared}
            fontSize="1.5rem"
            color={Boolean(hasInterpretation) ? "green" : "gray.400"}
            onClick={isOpen ? undefined : onExpand}
            cursor="pointer"
            sx={{
              position: "relative",
              left: "50px",
            }}
          />
          <Badge
            bgColor={ORDER_STATUS_COLOR[item.order?.status || "InProcess"]}
            fontWeight="700"
            fontSize="15px"
            color="white"
            borderRadius="20px"
            padding="0.5rem 0.5rem"
            textTransform="capitalize"
            width="100%"
            textAlign="center"
          >
            {enumMapper.toDisplay(
              "orderStatus",
              item.order?.status || "InProcess"
            )}
          </Badge>
          <Icon
            as={RightArrow}
            w={6}
            h={6}
            fontSize="1.5rem"
            onClick={onExpand}
            cursor="pointer"
            color="blue"
            transform={!isOpen ? "rotate(90deg)" : "rotate(270deg)"}
          />
        </Box>
      </Card>

      <Box
        sx={{
          height: fromSummary || isOpen ? "auto" : "0",
          overflow: "hidden",
          padding: "5px",
        }}
      >
        <Box sx={{ padding: "15px" }}>
          <RadiologyChartFileWrapper
            orderId={orderId ?? ""}
            isFileMode={isFileMode}
            toggleFileMode={() => setFileMode(!isFileMode)}
          />
          <RadiologyItemSignature index={index} />
        </Box>

        {!isFileMode && !hideInterpretations && (
          <>
            <ToggableChartWrappers
              index={index}
              chartCode={chartCode}
              sectionCode={sectionCode}
              encounterId={encounterId}
              onToggleInterpretation={onToggleInterpretation}
            />

            <RadiologyInterpretations
              type="FRACTURE"
              isReadOnly={isReadOnly}
              chartId={chartCode}
              sectionId={sectionId}
              onAddInterpretation={onAddInterpretation}
            />
            <InterpretationElements
              index={index}
              type="FRACTURE"
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={sectionCode}
              isReadOnly={isReadOnly}
              interpretations={(rad.interpretations || []).filter(
                (el) => el.type === "FRACTURE" && !el.deleted
              )}
              onRemoveElement={onRemoveInterpretation}
            />

            <RadiologyInterpretations
              type="DISLOCATION"
              isReadOnly={isReadOnly}
              chartId={chartCode}
              sectionId={sectionId}
              onAddInterpretation={onAddInterpretation}
            />
            <InterpretationElements
              index={index}
              type="DISLOCATION"
              chartCode={chartCode}
              encounterId={encounterId}
              sectionCode={sectionCode}
              isReadOnly={isReadOnly}
              interpretations={(rad.interpretations || []).filter(
                (el) => el.type === "DISLOCATION" && !el.deleted
              )}
              onRemoveElement={onRemoveInterpretation}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

function RadiologyChartTable(props: RadiologyChartTableProps) {
  const {
    chartCode,
    sectionCode,
    items,
    filteredItems,
    isReadOnly,
    hideInterpretations,
  } = props;

  return Boolean((items || []).length) ? (
    <>
      <RadiologyHeaders />
      {filteredItems.map((item) => (
        <RadiologyItem
          index={item.index}
          item={item}
          isReadOnly={isReadOnly}
          chartCode={chartCode}
          sectionId={item.order?.id ?? ""}
          sectionCode={sectionCode}
          hideInterpretations={hideInterpretations}
        />
      ))}
    </>
  ) : (
    <Box width="100%">
      <Box
        textAlign="center"
        fontSize="24px"
        fontWeight="500"
        color="gray.700"
        pt="200px"
      >
        No Radiology orders have been placed at this time.
      </Box>
    </Box>
  );
}

export { RadiologyChartTable };
