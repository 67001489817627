import * as React from "react";

import {
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  EKGForm,
  EKGSection,
  useChartSubsectionFile,
  useDeleteChartSubsectionFile,
  UserType,
  useSaveAndNext,
  useUploadChartSubsectionFile,
} from "modules";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import {
  Badge,
  Box,
  BoxProps,
  chakra,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  Button,
  Card,
  Loading,
  PdfViewer,
  PdfViewerActions,
  PdfViewerActivePage,
  PdfViewerContainer,
  PdfViewerHeader,
  PdfViewerPageIndicator,
  PdfViewerPreview,
  PdfViewerProps,
  StaffAvatar,
  Tooltip,
} from "shared";

import { ChartForm } from "modules/charts-shared-v2";
import {
  EmrForm,
  EmrHeartBolt,
  EmrInProcess,
  EmrProfileComplete,
  EmrRegistration,
  EmrSignature,
} from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { enumMapper, extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEkg, useUpdateEKG } from "../api";
import { OrderStatus } from "enums";
import { OrderHeaderCard, ORDER_STATUS_COLOR } from "modules/orders";
import { useState } from "react";
import { formToPayload, responseToForm } from "../utils/ekg";
import { Collapse, EditSquared, Expand, Import, SquaredChecked } from "icons";
import { useChartTrackingContext } from "contexts";
import { AxiosError, AxiosResponse } from "axios";
import { USERID_CLAIMS } from "system-constants";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetUserSignature } from "modules/onboarding/api";
import {
  getChartRoute,
  GetChartRouteParams,
} from "modules/in-process/components";
import { useUserRoles } from "contexts/UserRoles";
import { useGetUserDetails } from "api";

const SECTION_CODE = "SEKG";
const chartCode = "C001x";

const GRID_PROPS: BoxProps = {
  padding: "1rem 0",
  display: "grid",
  gridTemplateColumns: "0.75fr 3fr 2fr 10fr 8fr",
  gridColumnGap: "1rem",
  alignItems: "center",
  justifyItems: "center",
};

type EKGPdfViewerProps = {
  isReadOnly?: boolean;
  subsectionId: string;
  fileData: AxiosResponse<Blob> | undefined;
  dataError: AxiosError<unknown> | null;
  isFileLoading: boolean;
  expanded?: boolean;
  onToggleExpand?: () => void;
  pageWidth?: number;
  pageHeight?: number;
  maxWidth?: string;
};

function EKGPdfViewer(props: EKGPdfViewerProps) {
  const {
    isReadOnly = false,
    subsectionId,
    fileData,
    dataError,
    isFileLoading,
    expanded,
    onToggleExpand,
    pageWidth = 400,
    pageHeight = 520,
    maxWidth = "470px",
  } = props;
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const toast = useToast();

  const [file, setFile] = React.useState<Nullable<File | string | Blob>>();
  const [numPages, setNumPages] = React.useState(0);
  const [activePageNumber, setActivePageNumber] = React.useState(1);
  const { mutateAsync: uploadFile, isLoading: isUploadLoading } =
    useUploadChartSubsectionFile({
      encounterId,
      subsectionId,
    });
  const { mutateAsync: deleteFile, isLoading: isDeleteLoading } =
    useDeleteChartSubsectionFile(encounterId, subsectionId);

  const onFileChange = async (newFile: Nullable<string | File | Blob>) => {
    try {
      if (typeof newFile === "string") {
        return;
      }

      if (newFile instanceof File) {
        const formData = new FormData();
        formData.append("file", newFile as Blob, newFile.name);
        await uploadFile(formData);
        setActivePageNumber(1);
        return;
      }

      if (newFile instanceof Blob) {
        const formData = new FormData();
        formData.append("file", newFile, "file");
        setActivePageNumber(1);
        return;
      }
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const onFileDelete: PdfViewerProps["onDelete"] = async () => {
    try {
      await deleteFile(activePageNumber);
      setActivePageNumber(1);
      setNumPages(0);
      setFile(null);
    } catch (error) {
      setActivePageNumber(1);
      setNumPages(0);
      setFile(null);
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (fileData?.data) {
      setFile(fileData.data);
    }
  }, [fileData]);

  React.useEffect(() => {
    if (dataError) {
      setFile(undefined);
    }
  }, [dataError]);

  return (
    <PdfViewer
      value={file}
      onChange={onFileChange}
      numPages={numPages}
      setNumPages={setNumPages}
      activePageNumber={activePageNumber}
      setActivePageNumber={setActivePageNumber}
      onDelete={onFileDelete}
    >
      <PdfViewerContainer
        maxWidth={maxWidth}
        maxH="-webkit-max-content"
        customContent={
          <Flex
            minWidth="470px"
            margin="0.5rem 1rem"
            justifyContent="center"
            alignItems="center"
            border="1px solid"
            borderColor="red"
            borderRadius="10px"
            backgroundColor="gray.200"
            cursor="pointer"
          >
            <Flex direction="column" alignItems="center" gap="0.5rem">
              <Flex
                w="60px"
                h="60px"
                justifyContent="center"
                alignItems="center"
                padding="10px"
                backgroundColor="red"
                borderRadius="60px"
              >
                <Icon as={Import} color="white" fontSize="30px" />
              </Flex>
              <chakra.span color="red" fontWeight={500} fontSize="17px">
                Import EKG Strip
              </chakra.span>
            </Flex>
          </Flex>
        }
        activePage={
          <PdfViewerActivePage pageWidth={pageWidth} pageHeight={pageHeight} />
        }
        header={
          <PdfViewerHeader
            title="EKG"
            leftElements={[
              <PdfViewerPageIndicator key="page-indicator" />,
              <IconButton
                key="expand-collapse-button"
                aria-label="submit document"
                icon={<Icon as={expanded ? Collapse : Expand} />}
                color="blue"
                variant="icon"
                onClick={onToggleExpand}
              />,
            ]}
            rightElements={[
              !isReadOnly && (
                <PdfViewerActions
                  key="viewer-actions"
                  isSubmitLoading={isFileLoading || isUploadLoading}
                  isDeleteLoading={isDeleteLoading}
                />
              ),
            ]}
          />
        }
        preview={<PdfViewerPreview />}
      />
    </PdfViewer>
  );
}

type EKGCountersProps = {
  filter: OrderStatus | null;
  setFilter: (filter: OrderStatus | null) => void;
  useFormContext: UseFormReturn<EKGForm>;
};

function EKGCounters({ filter, setFilter, useFormContext }: EKGCountersProps) {
  const { watch } = useFormContext;

  const subsections = watch("ekg");

  const handleSetFilter = (newFilter: OrderStatus) => {
    if (filter === newFilter) {
      setFilter(null);
    } else {
      setFilter(newFilter);
    }
  };

  const getCounter = React.useCallback(
    (status: string) => {
      let counter = 0;
      if (subsections) {
        counter = subsections?.filter(
          (subsection: EKGSection) => subsection?.status === status
        ).length;
      }
      return counter;
    },
    [subsections]
  );

  return (
    <Flex columnGap="30px">
      <OrderHeaderCard
        title="Ordered"
        icon={<Icon as={EmrRegistration} />}
        bg={filter === "Ordered" || filter === null ? "orange" : "white"}
        color={filter === "Ordered" || filter === null ? "white" : "gray"}
        cursor="pointer"
        onClick={() => handleSetFilter("Ordered")}
        counter={getCounter("Ordered")}
        flex={1}
      />
      <OrderHeaderCard
        title="In Process"
        icon={<Icon as={EmrInProcess} />}
        bg={filter === "InProcess" || filter === null ? "blue" : "white"}
        color={filter === "InProcess" || filter === null ? "white" : "gray"}
        cursor="pointer"
        onClick={() => handleSetFilter("InProcess")}
        counter={getCounter("InProcess")}
        flex={1}
      />
      <OrderHeaderCard
        title="Completed"
        icon={<Icon as={SquaredChecked} />}
        bg={filter === "Completed" || filter === null ? "green" : "white"}
        color={filter === "Completed" || filter === null ? "white" : "gray"}
        cursor="pointer"
        onClick={() => handleSetFilter("Completed")}
        counter={getCounter("Completed")}
        flex={1}
      />
    </Flex>
  );
}

function EKGHeaders() {
  return (
    <Card
      bg="gray.250"
      boxShadow="none"
      borderBottomRadius="unset"
      {...GRID_PROPS}
    >
      <Box>
        <Icon
          as={EmrProfileComplete}
          color="gray.650"
          mt="5px"
          w="32px"
          h="auto"
        />
      </Box>
      <chakra.span>Date / Time</chakra.span>
      <chakra.span justifySelf="left">Order ID</chakra.span>
      <chakra.span justifySelf="left">Order Notes</chakra.span>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr 1.5fr 1fr"
        width="100%"
        justifyItems="center"
      >
        <chakra.span>Interpretation</chakra.span>
        <chakra.span>Signature</chakra.span>
        <chakra.span>Status</chakra.span>
        <chakra.span>EKG Strip</chakra.span>
      </Box>
    </Card>
  );
}

type EKGItemProps = {
  item: EKGSection;
  index: number;
  isReadOnly?: boolean;
  useFormContext: UseFormReturn<EKGForm>;
};

function EKGItem({ index, useFormContext, isReadOnly }: EKGItemProps) {
  const { watch, setValue } = useFormContext;
  const location = useLocation();
  const fromSummary = location.pathname.includes("medical-records");

  const [isExpanded, setIsExpanded] = useState(fromSummary);

  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const { user } = useAuth0();

  const { data: signatureData } = useGetUserSignature(user?.[USERID_CLAIMS]);

  const orderId = watch(`ekg.${index}.order.orderId`);
  const notes = watch(`ekg.${index}.notes`) as string;
  const userId = watch(`ekg.${index}.userId`);
  const ekgDate = watch(`ekg.${index}.C001x${SECTION_CODE}Q001A001`);

  const { data: userDetails } = useGetUserDetails(userId as string);

  const ekg = watch(`ekg.${index}`);

  const signedBy = watch(`ekg.${index}.signedBy`);

  const signature = React.useMemo(() => {
    if (!signedBy && !ekg.signature) {
      return null;
    }

    if (signedBy && !ekg.signature) {
      return signatureData?.data;
    }

    if (signedBy && ekg.signature) {
      return ekg.signature;
    }
  }, [ekg.signature, signatureData?.data, signedBy]);

  const setPicture = React.useCallback(() => {
    if (!(isReadOnly || !!ekgDate)) {
      setValue(`ekg.${index}.status`, "InProcess");
    }

    setValue(`ekg.${index}.userId`, user?.[USERID_CLAIMS], {
      shouldDirty: true,
    });
  }, [isReadOnly, ekgDate, setValue, index, user]);

  const hasInterpretation = ekg
    ? Object.keys(ekg)
        .filter((x) => x.includes("C001"))
        .some((x) => Boolean(ekg[x]))
    : false;

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  const {
    data: fileData,
    isLoading: isFileLoading,
    error: dataError,
  } = useChartSubsectionFile({
    encounterId,
    subsectionId: ekg.id as string,
  });

  const handleSignOrder = () => {
    setValue(`ekg.${index}.status`, "Completed");
    setValue(`ekg.${index}.signedBy`, user?.[USERID_CLAIMS]);
  };

  const {
    isOpen: expanded,
    onClose: onCloseExpand,
    onToggle: onToggleExpand,
  } = useDisclosure();

  const { roles } = useUserRoles();

  const isAllowedToSign = React.useMemo(() => {
    const rolesAllowedToViewOpenChartCounter: UserType[] = [
      "Administrator",
      "SupervisingProvider",
      "Provider",
    ];

    return roles.some((x) => rolesAllowedToViewOpenChartCounter.includes(x));
  }, [roles]);

  return (
    <Box borderRadius="10px">
      <EKGHeaders />
      <Box
        backgroundColor="white"
        border="1px solid"
        borderColor="gray.400"
        borderTop="unset"
        borderBottomRadius="16px"
      >
        <Box>
          <Box {...GRID_PROPS}>
            <StaffAvatar
              size="xs"
              fontSize=".75rem"
              userName={userDetails?.data?.fullName}
              profileUrl={userDetails?.data?.pictureUrl}
            />
            <chakra.span>
              <ChartRowElement
                isDisabled={isReadOnly}
                type="date"
                name={`ekg.${index}.C001x${SECTION_CODE}Q001A001`}
                onClick={setPicture}
              />
            </chakra.span>
            <chakra.span justifySelf="left">{orderId}</chakra.span>
            <chakra.span justifySelf="left">{notes}</chakra.span>

            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr 1.5fr 1fr"
              width="100%"
              justifyItems="center"
            >
              <Icon
                as={EditSquared}
                fontSize="1.5rem"
                color={Boolean(hasInterpretation) ? "green" : "gray.400"}
                onClick={toggleExpand}
                cursor="pointer"
              />

              <Icon
                as={EmrSignature}
                fontSize="1.5rem"
                color={!!signature ? "green" : "gray.400"}
                onClick={toggleExpand}
                cursor="pointer"
              />

              <Badge
                bgColor={
                  ORDER_STATUS_COLOR[(ekg.status as OrderStatus) || "InProcess"]
                }
                cursor="pointer"
                onClick={toggleExpand}
                userSelect="none"
                fontWeight="700"
                fontSize="15px"
                color="white"
                borderRadius="20px"
                padding="0.5rem 0.5rem"
                textTransform="capitalize"
                width="100%"
                textAlign="center"
              >
                {enumMapper.toDisplay(
                  "ekgStatus",
                  (ekg.status as OrderStatus) || "InProcess"
                )}
              </Badge>

              <Icon
                as={EmrForm}
                fontSize="1.5rem"
                color={Boolean(fileData) ? "green" : "gray.400"}
                onClick={toggleExpand}
                cursor="pointer"
              />
            </Box>
          </Box>
        </Box>

        {isExpanded && (
          <Box overflow="hidden" padding="5px" paddingBottom="1rem">
            <chakra.span ml="64px" fontWeight={400} fontSize="15px">
              To complete the EKG, the provider must fill out the interpretation
              in either the Rythm Strip or EKG subsection and add their
              Signature.
            </chakra.span>

            <Flex>
              <Box flex={1}>
                <Box sx={{ padding: "10px" }}>
                  <ChartSubsection
                    header={
                      <ChartSubsectionHeader
                        chartId={chartCode}
                        encounterId={encounterId}
                        sectionId={SECTION_CODE}
                        subsectionId="Q002"
                        subsectionLabel="Rhythm Strip"
                      >
                        Rhythm Strip
                      </ChartSubsectionHeader>
                    }
                    content={
                      <ChartSubsectionContent>
                        <ChartRow>
                          <ChartRowElement
                            type="cross-option"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q002A001`}
                            label="Rate:"
                          />
                          <ChartRowElement
                            type="input"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q002A002`}
                            size="sm"
                          />
                          <ChartRowElement
                            type="cross-option"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q002A003`}
                            label="Rhythm:"
                          />
                          <ChartRowElement
                            type="cross-option"
                            label="NSR"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q002A004`}
                          />
                          <ChartRowElement
                            type="input"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q002A005`}
                          />
                        </ChartRow>
                        <ChartRow>
                          <ChartRowElement
                            type="input"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q002A006`}
                          />
                        </ChartRow>
                      </ChartSubsectionContent>
                    }
                  />
                </Box>

                <Box sx={{ padding: "10px" }}>
                  <ChartSubsection
                    header={
                      <ChartSubsectionHeader
                        chartId={chartCode}
                        encounterId={encounterId}
                        sectionId={SECTION_CODE}
                        subsectionId="Q003"
                        subsectionLabel="EKG"
                      >
                        EKG
                      </ChartSubsectionHeader>
                    }
                    content={
                      <ChartSubsectionContent>
                        <ChartRow>
                          <ChartRowElement
                            type="cross-option"
                            label="Interpreted by me"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A001`}
                          />
                          <ChartRowElement
                            type="cross-option"
                            label="Viewed by me"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A002`}
                          />
                          <ChartRowElement
                            type="input"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A003`}
                          />
                        </ChartRow>
                        <ChartRow>
                          <ChartRowElement
                            type="cross-option"
                            label="nml/NAD"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A004`}
                          />
                          <ChartRowElement
                            type="cross-option"
                            label="nml intervals"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A005`}
                          />
                          <ChartRowElement
                            type="cross-option"
                            label="nml axis"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A006`}
                          />
                          <ChartRowElement
                            type="cross-option"
                            label="nml QRS"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A007`}
                          />
                          <ChartRowElement
                            type="cross-option"
                            label="nml ST/T"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A008`}
                          />
                          <ChartRowElement type="empty" />
                        </ChartRow>
                        <ChartRow>
                          <ChartRowElement
                            type="cross-option"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A009`}
                            label="Rate:"
                          />
                          <ChartRowElement
                            type="input"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A010`}
                            size="sm"
                          />
                          <ChartRowElement
                            type="cross-option"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A011`}
                            label="Rhythm:"
                          />
                          <ChartRowElement
                            type="cross-option"
                            label="NSR"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A012`}
                          />
                          <ChartRowElement
                            type="cross-option"
                            label="sinus tach"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A013`}
                          />
                          <ChartRowElement
                            type="cross-option"
                            label="A-fib"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A014`}
                          />
                          <ChartRowElement
                            type="input"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A015`}
                          />
                        </ChartRow>
                        <ChartRow>
                          <ChartRowElement
                            type="cross-option"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A016`}
                            label="changed"
                          />
                          <ChartRowElement
                            type="cross-option"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A018`}
                            label="not changed"
                          />
                          <ChartRowElement
                            type="cross-option"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A019`}
                            label="from:"
                            fontStyle="italic"
                          />
                          <ChartRowElement
                            type="input"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A017`}
                            size="sm"
                          />
                          <ChartRowElement
                            type="input"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A020`}
                          />
                        </ChartRow>
                        <ChartRow>
                          <ChartRowElement
                            type="input"
                            name={`ekg.${index}.C001x${SECTION_CODE}Q003A021`}
                          />
                        </ChartRow>
                      </ChartSubsectionContent>
                    }
                  />
                </Box>
                <Box height="100px" p={6} width="100%" bgColor="white">
                  <HStack width="100%" spacing={6}>
                    <HStack
                      display="flex"
                      justifyContent="start"
                      spacing={4}
                      flex={2}
                    >
                      <Text fontWeight="600" fontSize="18px">
                        Provider Signature
                      </Text>
                    </HStack>
                    <Flex justifyContent="center" flex={1}>
                      <HStack>
                        <StaffAvatar
                          w="40px"
                          h="40px"
                          size="xs"
                          fontSize=".75rem"
                          userName={signature?.fullName}
                          profileUrl={signature?.pictureUrl}
                        />
                        <VStack spacing="0" alignItems="flex-start">
                          <Box
                            fontSize="1.0625rem"
                            fontWeight="600"
                            lineHeight="1.5rem"
                            color="black"
                          >
                            {signature?.fullName}
                          </Box>
                          <Box
                            fontSize="0.875rem"
                            fontWeight="400"
                            lineHeight="1rem"
                            color="gray.700"
                          >
                            {signature?.teams}
                          </Box>
                        </VStack>
                      </HStack>
                    </Flex>
                    <HStack
                      pl={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      spacing={40}
                      flex={2}
                    >
                      <HStack spacing={10}>
                        <Text fontWeight="600" fontSize="16px" color="gray.700">
                          E-signed by:
                        </Text>
                        {!!signature ? (
                          <Box minW={300} maxW={400} minH={50}>
                            <Image
                              src={signature?.signature}
                              height="100%"
                              width="100%"
                            />
                          </Box>
                        ) : (
                          <Tooltip
                            isOpen={!isAllowedToSign}
                            label="Only Providers are allowed to sign"
                          >
                            <Button
                              variant="label"
                              color="blue"
                              onClick={handleSignOrder}
                              disabled={!isAllowedToSign}
                            >
                              <Icon
                                as={EmrSignature}
                                fontSize="1.5rem"
                                color="blue"
                                m={2}
                              />
                              Add Signature
                            </Button>
                          </Tooltip>
                        )}
                      </HStack>
                    </HStack>
                  </HStack>
                </Box>
              </Box>

              <EKGPdfViewer
                isReadOnly={isReadOnly}
                subsectionId={ekg.id as string}
                fileData={fileData}
                dataError={dataError}
                isFileLoading={isFileLoading}
                expanded={expanded}
                onToggleExpand={onToggleExpand}
              />

              <Modal isOpen={expanded} onClose={onCloseExpand}>
                <ModalOverlay />
                <ModalContent maxW="min-content">
                  <EKGPdfViewer
                    isReadOnly
                    subsectionId={ekg.id as string}
                    fileData={fileData}
                    dataError={dataError}
                    isFileLoading={isFileLoading}
                    expanded={expanded}
                    onToggleExpand={onToggleExpand}
                    pageWidth={800}
                    pageHeight={500}
                    maxWidth="unset"
                  />
                </ModalContent>
              </Modal>
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
}

function EKG({
  isReadOnly = false,
  chartCode: originChartCode,
}: {
  chartCode: string;
  isReadOnly?: boolean;
}) {
  const toast = useToast();

  const [filter, setFilter] = useState<OrderStatus | null>(null);
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<EKGForm>();

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
    getValues,
  } = useFormContext;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useEkg(encounterId);

  const { mutateAsync: updateEkg, isLoading: isUpdateLoading } =
    useUpdateEKG(encounterId);

  const onSubmit: SubmitHandler<EKGForm> = React.useCallback(
    async (values) => {
      try {
        const payload = formToPayload(values);
        await updateEkg(payload);

        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("EKG") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [reset, toast, updateEkg]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "ekg",
    originChartCode[0] === "T" ? "nurse" : "provider",
    isDirty
  );

  const formObject = getValues();
  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = formToPayload(formObject);
      await updateEkg(payload);
      toast({ description: formMessages.updateSuccess("EKG") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = responseToForm(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  const subsections = watch("ekg");

  const filteredSubsections = React.useMemo(() => {
    if (!subsections) {
      return [];
    }

    return subsections
      .map((subsection: EKGSection, index: number) => {
        return {
          ...subsection,
          index,
        };
      })
      .filter((subsection: EKGSection) => {
        if (filter) {
          return subsection.status === filter;
        }
        return true;
      });
  }, [subsections, filter]);

  const { isPastEncounter } = useChartTrackingContext();

  const isReadOnlyOrPastEncounter = isReadOnly || isPastEncounter;

  const { roles } = useUserRoles();
  const navigate = useNavigate();

  const chartParams: GetChartRouteParams = {
    roles,
    encounterId,
    routeParams: `tab=ekg`,
    sectionRoute: "order-form",
  };

  if (originChartCode[0] === "T") {
    chartParams.nursingChart = {
      lastChartCode: originChartCode,
      nurse: null,
      timer: null,
    };
  } else {
    chartParams.providerChart = {
      lastChartCode: originChartCode,
      provider: null,
      timer: null,
    };
  }
  const chartRoute = getChartRoute(chartParams);

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={isUpdateLoading}
        disabled={isReadOnly}
        display="flex"
        flexDir="column"
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHeartBolt} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={SECTION_CODE}
          showActionsBtns
          isLoading={isUpdateLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          EKG {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>

        <Button
          variant="action"
          onClick={() => navigate(chartRoute)}
          alignSelf="flex-end"
        >
          Order New EKG
        </Button>

        <EKGCounters
          filter={filter}
          setFilter={(newFilter) => setFilter(newFilter)}
          useFormContext={useFormContext}
        />

        {Boolean(data?.data.length) ? (
          <Flex gap="1rem" flexDir="column">
            {filteredSubsections.map((item) => (
              <EKGItem
                index={item.index}
                item={item}
                isReadOnly={isReadOnlyOrPastEncounter}
                useFormContext={useFormContext}
              />
            ))}
          </Flex>
        ) : (
          <Box width="100%">
            <Box
              textAlign="center"
              fontSize="24px"
              fontWeight="500"
              color="gray.700"
              pt="200px"
            >
              No EKG orders have been placed at this time.
            </Box>
          </Box>
        )}
      </ChartForm>
    </FormProvider>
  );
}

export { EKG };
